import { Injectable } from '@angular/core'
import { ConfigService } from './config.service'
import { LogService } from './log.service'
import { availableThemes } from '../interfaces/theme.interface'

@Injectable()
export class ThemeService {
  constructor(
    private configService: ConfigService,
    private logger: LogService,
  ) {}

  get theme(): string {
    return this.configService.config().theme.id
  }

  set theme(themeId: string) {
    this.configService.config().theme.id = themeId
  }

  init(): void {
    this.theme = this.configService.config().theme.id ?? availableThemes[0].id
    this.logger.info('Theme service initialized ::', this.theme)
  }

  setStyleOnElement(cssKey: string, value: string, el: HTMLElement): void {
    el.style.setProperty(`--${cssKey}`, value)
  }

  setStylesOnElement(styles: { [key: string]: string }, el: HTMLElement): void {
    if (!styles) {
      return
    }
    Object.entries(styles)
      .filter(([, value]: [string, string]) => {
        return value !== undefined
      })
      .forEach(([key, value]: [string, string]) => {
        this.setStyleOnElement(key, value, el)
      })
  }

  clearStylesOnElement(elRef: HTMLElement): void {
    elRef.removeAttribute('style')
  }
}
