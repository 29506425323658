
export enum CurrencyCode {
  USD = 'USD',
  CAD = 'CAD',
  MXN = 'MXN',
  EUR = 'EUR',
  JPY = 'JPY',
  AUD = 'AUD',
  CNY = 'CNY',
  KRW = 'KRW',
}

export interface ICurrency {
  name: string
  code: CurrencyCode
  symbol: string
  rate?: number
}

export interface ICurrencyRateData {
  currency: CurrencyCode
  rate: number
}



export const usdCurrency = {
  name: 'United States dollar',
  code: CurrencyCode.USD,
  symbol: '$',
  rate: 1,
}

export const supportedCurrencies: ICurrency[] = [{
  ...usdCurrency,
}, {
  name: 'Canadian dollar',
  code: CurrencyCode.CAD,
  symbol: '$',
}, {
  name: 'Mexican peso',
  code: CurrencyCode.MXN,
  symbol: '$',
}, {
  name: 'Euro',
  code: CurrencyCode.EUR,
  symbol: '€',
}, {
  name: 'Japanese yen',
  code: CurrencyCode.JPY,
  symbol: '¥',
}, {
  name: 'Australian dollar',
  code: CurrencyCode.AUD,
  symbol: '$',
}, {
  name: 'Chinese renminbi',
  code: CurrencyCode.CNY,
  symbol: '¥',
}, {
  name: 'Korean won',
  code: CurrencyCode.KRW,
  symbol: '₩',
}]
