import { CurrencyCode, ICurrency, ICurrencyRateData } from '../interfaces/currency.interface'

export const currencyRates: ICurrencyRateData[] = [{
  currency: CurrencyCode.USD,
  rate: 1.0
}, {
  currency: CurrencyCode.CAD,
  rate: 1.345532831001076
}, {
  currency: CurrencyCode.MXN,
  rate: 17.54385964912280
}, {
  currency: CurrencyCode.EUR,
  rate: 0.917262887543569
}, {
  currency: CurrencyCode.JPY,
  rate: 138.8888888888888
}, {
  currency: CurrencyCode.AUD,
  rate: 1.503081316699233
}, {
  currency: CurrencyCode.CNY,
  rate: 7.062146892655367
}, {
  currency: CurrencyCode.KRW,
  rate: 1.25E+3
}]


export const defaultCurrency = {
  name: 'United States dollar',
  code: CurrencyCode.USD,
  symbol: '$',
  rate: 1,
}

export const supportedCurrencies: ICurrency[] = [{
  ...defaultCurrency,
}, {
  name: 'Canadian dollar',
  code: CurrencyCode.CAD,
  symbol: '$',
}, {
  name: 'Mexican peso',
  code: CurrencyCode.MXN,
  symbol: '$',
}, {
  name: 'Euro',
  code: CurrencyCode.EUR,
  symbol: '€',
}, {
  name: 'Japanese yen',
  code: CurrencyCode.JPY,
  symbol: '¥',
}, {
  name: 'Australian dollar',
  code: CurrencyCode.AUD,
  symbol: '$',
}, {
  name: 'Chinese renminbi',
  code: CurrencyCode.CNY,
  symbol: '¥',
}, {
  name: 'Korean won',
  code: CurrencyCode.KRW,
  symbol: '₩',
}]
