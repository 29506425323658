import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { FetchService } from './fetch.service'
import { ApiRequestService } from './api-request.service'
import { environment } from 'src/environments/environment'
import { LogService } from './log.service'
import { MockService } from './mock.service'
import { of } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(
    private logger: LogService,
    private mockService: MockService,
    private fetchService: FetchService,
    private apiRequestService: ApiRequestService,
  ) { }

  submitContactForm(formData: any): Observable<any> {
    this.logger.debug(`FormService.submitContactForm() formData: `, formData)
    if (environment.useMock) {
      return of(this.mockService.submitContactForm())
    }

    return this.fetchService.postRequest(this.apiRequestService.buildEndpoint('form/submit/webshop'), formData)
  }
}
