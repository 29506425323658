import { LanguageCode } from '../interfaces/language.interface'

export const defaultHomePageSiteTitle = {
  [LanguageCode.EN_US]: 'Home - Used Equipment',
  [LanguageCode.EN_CA]: 'Home - Used Equipment',
  [LanguageCode.ES_MX]: 'Inicio - Equipos Usados',
  [LanguageCode.ES_SP]: 'Inicio - Equipos Usados',
  [LanguageCode.FR_CA]: 'Accueil - Équipements Usagés',
}

export const defaultSearchPageSiteTitle = {
  [LanguageCode.EN_US]: 'Used Equipment',
  [LanguageCode.EN_CA]: 'Used Equipment',
  [LanguageCode.ES_MX]: 'Equipos Usados',
  [LanguageCode.ES_SP]: 'Equipos Usados',
  [LanguageCode.FR_CA]: 'Équipements Usagés',
}

export const defaultDetailsPageSiteTitle = {
  [LanguageCode.EN_US]: '<year> <make> <model> <category>',
  [LanguageCode.EN_CA]: '<year> <make> <model> <category>',
  [LanguageCode.ES_MX]: '<year> <make> <model> <category>',
  [LanguageCode.ES_SP]: '<year> <make> <model> <category>',
  [LanguageCode.FR_CA]: '<year> <make> <model> <category>',
}
