import { CommonModule } from '@angular/common'
import { Component, computed, ElementRef, HostBinding, Inject, OnInit, Signal, signal, ViewChild, WritableSignal } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { DialogService } from 'src/app/core/services/dialog.service'
import { ContactFormComponent } from '../../contact-form/contact-form.component'
import { ConfigService } from 'src/app/core/services/config.service'
import { Subject } from 'rxjs'
import { AssetCardComponent } from '../../asset-card/asset-card.component'
import { MatIconModule } from '@angular/material/icon'
import { ButtonModule } from '../../button/button.module'
import { MatButtonModule, MatIconButton } from '@angular/material/button'
import { FileUploadDataInputComponent } from 'src/app/admin/controls/data-input/file-upload/file-upload.component'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { IconButtonModule } from '../../icon-button/icon-button.module'
import { EventService } from 'src/app/core/services/event.service'
import { ClientService } from 'src/app/core/services/client.service'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { IMediaData } from 'src/app/core/interfaces/media-library.interface'
import { MediaLibraryService } from 'src/app/core/services/media-library.service'
import { IMediaLibraryDialogOptions } from 'src/app/core/interfaces/dialog.interface'

type IMediaDataWithLocalProperties = IMediaData & { loaded?: boolean, isHidden?: boolean }
@Component({
  selector: 'media-library-component',
  styleUrls: ['media-library.component.scss'],
  standalone: true,
  templateUrl: './media-library.component.html',
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconButton,
    ContactFormComponent,
    AssetCardComponent,
    ButtonModule,
    FileUploadDataInputComponent,
    MatProgressSpinnerModule,
    IconButtonModule,
  ],
})
export class MediaLibraryDialogComponent implements OnInit {
  protected submitEventNotifier = new Subject<void>()
  @HostBinding('class.submission-confirmed') submitTrue: boolean = false
  @ViewChild('searchInput') searchInput!: ElementRef

  @ViewChild('fileUploader') fileUploader!: FileUploadDataInputComponent


  images: WritableSignal<IMediaDataWithLocalProperties[]> = signal([])
  filteredImages: Signal<IMediaDataWithLocalProperties[]> = computed(() => this.images().filter(e => !e.isHidden))
  showInspector: boolean = false
  inspectedImage: IMediaDataWithLocalProperties | null = null
  selected: IMediaDataWithLocalProperties[] = []
  uploadingFiles: string[] = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IMediaLibraryDialogOptions,
    public dialogRef: MatDialogRef<MediaLibraryDialogComponent>,
    protected configService: ConfigService,
    protected mediaLibraryService: MediaLibraryService,
    protected dialogService: DialogService,
    protected eventService: EventService,
    protected clientService: ClientService,
  ) {}

  ngOnInit(): void {
    this.mediaLibraryService.getMediaLibrary().then((data: IMediaDataWithLocalProperties[]) => {
      this.images.set(data.sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime()))
      this.searchInput.nativeElement.value = ''
    })
    this.eventService.mediaLibraryUploaded$.subscribe((data: { originalFileName: string; fileName: string }) => {
      this.uploadingFiles = this.uploadingFiles.filter(e => e !== data.originalFileName)
      if (this.uploadingFiles.length === 0) {
        this.mediaLibraryService.getMediaLibrary().then((images: IMediaDataWithLocalProperties[]) => {
          this.images.set(images.sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime()))
          this.searchInput.nativeElement.value = ''
        })
      }
    })
    this.eventService.mediaLibraryFileUploadFailed$.subscribe((data: { originalFileName: string }) => {
      this.uploadingFiles = this.uploadingFiles.filter(e => e !== data.originalFileName)
      this.images.set(this.images().filter(e => e.name !== data.originalFileName))
      this.searchInput.nativeElement.value = ''
    })
  }

  showFileName(name: string): string {
    const hash = name.match(/-\w{12}/)
    return hash ? name.replace(hash[0], '') : name
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onSubmitSuccess(): void {
    this.submitTrue = true
  }

  showImageOverlay(image: IMediaData): void {
    this.showInspector = true
    this.inspectedImage = image
  }

  hideImageOverlay(): void {
    this.showInspector = false
  }

  selectImage(image: IMediaData | null): void {
    this.selected = image && this.selected.map(e => JSON.stringify(e)).includes(JSON.stringify(image)) ? [] : [image as IMediaData]
    this.hideImageOverlay()
  }

  confirmSelection(): void {
    if (this.data.onSelect) {
      this.data.onSelect(this.selected[0])
      this.dialogRef.close()
    }
  }

  imageLoaded(idx: number): void {
    this.images()[idx].loaded = true
  }

  deleteImage(image: IMediaData): void {
    this.dialogService.confirmAdmin({
      title: 'Are you sure you want to remove this image from the library?'
    }).afterClosed().subscribe(choice => {
      if (choice === 'confirm') {
        this.mediaLibraryService.deleteFiles([image.name]).then(() => {
          this.images.set(this.images().filter(e => e.name !== image.name))
        })
      }
    })
  }

  onFileUpload(): void {
    const files = this.fileUploader.getValue()
    if (files.length) {
      this.uploadingFiles = Array.from(files).map((file: File) => file.name)
      Array.from(files).map((file: File) => {
        this.images().push({
          name: file.name,
          originalFileName: file.name,
          updated: new Date().toISOString(),
          loaded: false,
          kb: file.size,
          width: 0,
          height: 0,
          urls: {
            thumbnail: '',
            full: '',
            unsized: '',
          },
          updatedFormatted: new Date().toLocaleDateString(),
        })
      })
    }
    this.images.set(this.images().sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime()))
  }

  onCLickConfirmSelection() {
    return this.data.mode === 'select' ? this.confirmSelection() : this.onClose()
  }

  onFindImage(event: any) {
    const searchTerm = event.target.value
    if (!searchTerm) {
      this.onClearSearch()
      return
    }

    this.images.set(this.images().map((image) => {
      image.isHidden = !image.originalFileName.toLowerCase().includes(searchTerm.toLowerCase())
      return image
    }))
  }

  onClearSearch() {
    this.images.set(this.images().map((image) => {
      image.isHidden = false
      return image
    }))
    this.searchInput.nativeElement.value = ''
  }
}
