import { environment } from 'src/environments/environment'
import { defaultLegacyImageProcessorOptions, ILegacyImageProcessorOptions } from '../interfaces/image.interface'

export const buildLegacyProcessorSrc = (options: ILegacyImageProcessorOptions): string => {
  const {
    transparent = defaultLegacyImageProcessorOptions.transparent,
    type = defaultLegacyImageProcessorOptions.type,
  } = options
  let {
    guid = defaultLegacyImageProcessorOptions.guid,
  } = options
  if (Array.isArray(guid)) {
    guid = guid[0]
  }
  return `${environment.image.legacyApi}`
    + `?guid=${guid}`
    + `&type=${type}`
    + `&transparent=${transparent}`
}
