import { Injectable, inject } from '@angular/core'
import { KeysService } from './keys.service'
import { LogService } from './log.service'

declare global {
  interface Window {
      gtag: any
  }
}

@Injectable()
export class TagManagerService {
  private _logger: LogService = inject(LogService)
  private keysService: KeysService = inject(KeysService)
  window = inject(Window)

  init() {
    this._logger.debug('TagManagerService.init()')
    this.loadGoogleTagManager()
  }

  private async loadGoogleTagManager() {
    this._logger.debug('TagManagerService.loadGoogleTagManager()')
    const keys = this.keysService.keys()

    if (!keys.google_tag_manager?.length) {
      this._logger.debug('TagManagerService.loadGoogleTagManager() - No keys found')
      return
    }

    const code = keys.google_tag_manager

    await new Promise((resolve) => {
      const script = document.createElement('script')
      script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${code}');
      `
      const noscript = document.createElement('noscript')
      noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${code}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `
      document.body.insertBefore(noscript, document.body.firstChild)
      document.head.insertBefore(script, document.head.firstChild)

      resolve(1)
    })
  }
}
