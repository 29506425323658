import { Injectable } from '@angular/core'
import { ILegacyImageProcessorOptions } from 'src/app/core/interfaces/image.interface'
import { buildLegacyProcessorSrc } from 'src/app/core/utils/legacy-image.util'

@Injectable()
export class ImageService {
  buildLegacyProcessorSrc(options: ILegacyImageProcessorOptions): string {
    return buildLegacyProcessorSrc(options)
  }
}
