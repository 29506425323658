import { Component, ElementRef, OnInit, ViewChild, effect, inject, input } from '@angular/core'
import { DataInputComponent } from '../data-input.component'
import { environment } from 'src/environments/environment'
import { CommonModule } from '@angular/common'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { DialogService } from 'src/app/core/services/dialog.service'
import { ButtonModule } from 'src/app/components/button/button.module'
import { ToastService } from 'src/app/core/services/toast.service'
import { EMPTY_MEDIA_DATA, ImageSizes, IMediaData } from 'src/app/core/interfaces/media-library.interface'

@Component({
  selector: 'app-data-file-upload',
  standalone: true,
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss', '../../../../../assets/admin.scss'],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    ButtonModule,
  ],
})
export class FileUploadDataInputComponent extends DataInputComponent implements OnInit {
  dialogService = inject(DialogService)
  toastService = inject(ToastService)

  @ViewChild('dataInput') dataInput!: ElementRef
  @ViewChild('fileUploadWrapper') fileUploadWrapper!: ElementRef

  accept = input('.png,.jpeg,.jpg')
  recommendations = input('')
  multiple = input(false)
  file_max_size = input(2000000)
  size_error_message = input('File is larger than 2MB')
  extension_error_message = input('Format not supported')
  mediaData = input<IMediaData | undefined>(EMPTY_MEDIA_DATA)
  show_preview = input(true)
  show_media_library = input(true)

  Array = Array
  files: File[] = []
  reader: FileReader = new FileReader()
  tempFilesURLs: string[] = []
  tempFileErrors: string[] = []
  tempFileLoading: string[] = []
  isDragging: boolean = false
  ImageSizes = ImageSizes

  constructor() {
    super()
    effect(() => {
      this.updateFileUrl()
    })
  }

  override ngOnInit(): void {
    super.ngOnInit()
    this.updateFileUrl()
  }

  private updateFileUrl() {
    if (this.mediaData()?.name && this.mediaData()?.urls) {
      this.tempFilesURLs = [this.getImgThumbnail(this.mediaData()?.name)]
      const fileName = `${this.mediaData()?.name.split('/').pop() || ''}.${ImageSizes.Full}`
      const fileExt = fileName?.split('.').pop()
      const metadata = {
        type: `image/${fileExt}`
      }
      const file = new File([], this.mediaData()?.urls[ImageSizes.Full] ?? fileName, metadata)
      this.files = [file]
    }
  }

  getImgThumbnail(fileName?: string): string {
    if (!fileName) {
      return ''
    }
    const fileExt = fileName.split('.').pop()
    const fileNameWithoutExt = fileName.replace(`.${fileExt}`, '')
    return `${fileNameWithoutExt}.${ImageSizes.Thumbnail}.${fileExt}`
  }

  getValue(): File[] {
    return this.files ?? []
  }

  handleDragEnter(): void {
    this.isDragging = true
    this.cd.detectChanges()
  }

  handleDragLeave(): void {
    this.isDragging = false
    this.cd.detectChanges()
  }

  handleShowMediaLibrary(): void {
    this.dialogService.mediaLibrary({
      type: 'media-library',
      files: this.tempFilesURLs,
      onSelect: (data: any) => {
        this.updateDataValue(data)
      }
    })
  }

  handleAddClick(): void {
    if (this.show_media_library()) {
      this.handleShowMediaLibrary()
      return
    }
    this.dataInput.nativeElement.click()
  }

  handleRemoveClick(index: number): void {
    const fileList = Array.from(this.files)
    fileList.splice(index, 1)
    this.files = fileList
    this.tempFilesURLs.splice(index, 1)
    this.tempFileErrors.splice(index, 1)
    this.dataInput.nativeElement.value = ''
    this.updateDataValue(null)
  }

  onFileSelected(event: Event) {
    this.logger.debug(`file selected: `, event)
    const eventTarget = event.target as HTMLInputElement
    if (typeof (FileReader) !== 'undefined') {
      this.files = []
      this.tempFilesURLs = []
      const fileErrors: string[] = []
      let index = 0
      this.Array.from(eventTarget.files || []).map((file: File) => {
        this.tempFileLoading[index] = 'uploading'

        if (eventTarget?.files instanceof FileList) {
          if (eventTarget?.files[0]?.size > this.file_max_size()) {
            if (this.show_preview()) {
              this.files.push(file)
              this.tempFilesURLs.push(URL.createObjectURL(file))
              this.tempFileErrors[index] = this.size_error_message()
              index++
            } else {
              this.toastService.error({ message: this.size_error_message(), includeClose: true, duration: 60000 })
            }
            return
          }

          const acceptedExtensions = this.accept().split(',') ?? []
          const extension = eventTarget?.files[0]?.name.split('.').pop() ?? ''

          if (acceptedExtensions?.indexOf(`.${extension}`) < 0) {
            if (this.show_preview()) {
              this.files.push(file)
              this.tempFilesURLs.push(URL.createObjectURL(file))
              this.tempFileErrors[index] = this.extension_error_message()
              index++
            } else {
              this.toastService.error({ message: this.extension_error_message(), includeClose: true, duration: 60000 })
            }
            return
          }
        }
        this.files.push(file)
        this.tempFilesURLs.push(URL.createObjectURL(file))

        this.mediaLibraryService.uploadFile(file).then((fileName) =>{
          const fileExt = fileName.split('.').pop()
          const fileNameWithoutExt = fileName.split('.').slice(index, -1).join('.')
          const file_path = `source_images/${fileNameWithoutExt}.${fileExt}`
          if (this.key()) {
            this.updateDataValue(`${environment.image.api}/${file_path}`)
          }
          this.tempFileLoading[index] = ''
        })
          .catch((error) => {
            this.tempFileErrors[index] = `${error.userMessage}: ${error.error.description}`
            this.logger.error('Unable to upload file', error)
            fileErrors.push(file.name)
            this.toastService.error({ message: error.error.description, includeClose: true, duration: 60000 })
            this.tempFileLoading[index] = ''
          })
        index++
      })
    }
  }

  removeFileHash(fileName: string = ''): string {
    const hash = fileName.match(/-\w{12}/)
    return fileName.replace((hash?.[0] || ''), '')
  }

}
