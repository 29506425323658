import { inject, Injectable } from '@angular/core'
import { CurrencyCode } from '../interfaces/currency.interface'
import { IFormattedPrice } from '../interfaces/price.interface'
import { GlobalDataService } from './global-data.service'

@Injectable()
export class PriceService {

  private _globalData = inject(GlobalDataService)

  /**
   * This will take a price and convert it to the selected currency based on that currency's rate against USD.
   *
   * e.g. In an asset's data we have something like:
   * list_price: 143700.000,
   * list_price_native: 193015.447,
   * list_price_native_currency: 'CAD',
   *
   * The user had entered 193015.447 as CAD.  The USD price was calculated into 143700.000 and both were saved as asset data.
   * We can convert to other currencies by multiplying the USD price by the currency rate.  Or if we're displaying CAD here,
   * we can use the price they originally entered.
   *
   * @param usdPrice price in USD
   * @param nativePrice price set by user when they entered it
   * @param nativeCode currency code user had selected when they set the price
   * @param toCode code for currency we want the price to be converted to
   * @returns IFormattedPrice a price calculated based on the rate for the selected currency
   */
  calculatePriceForSelectedCurrency(usdPrice: number, nativePrice: number, nativeCode: CurrencyCode, toCode: CurrencyCode): IFormattedPrice {
    if (nativeCode === toCode) {
      return {
        price: nativePrice,
        code: nativeCode,
      }
    }
    const toCurrency = this._globalData.loadedCurrencies().find(c => c.code === toCode)
    if (!toCurrency || !toCurrency.rate) {
      // can't find requested currency or rate not set, default to USD
      return {
        price: usdPrice,
        code: CurrencyCode.USD,
      }
    }
    return {
      price: usdPrice * toCurrency.rate,
      code: toCode,
    }
  }
}
