import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'


export const LabelType = ['filled', 'outlined', 'reversed']
export type LabelType = typeof LabelType[number]
export const LabelModifier = ['', 'primary', 'secondary', 'error', 'warning', 'info']
export type LabelModifier = typeof LabelModifier[number]

@Component({
  selector: 'app-label',
  template: `
    <div class="Label Label-{{type}} Label-{{type}}{{getLabelModifier()}}">
      @if (leftIcon) {
        <mat-icon
          [class.material-icons-outlined]="outlinedIcon"
        >{{leftIcon}}</mat-icon>
      }
      @if (text) {
        <span
          class="Label__text"
        >{{text}}</span>
      }
      @if (rightIcon) {
        <mat-icon
          [class.material-icons-outlined]="outlinedIcon"
        >{{leftIcon}}</mat-icon>
      }
    </div>
  `,
  styleUrls: ['./label.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule]
})
export class LabelComponent {
  @Input() type?: LabelType = 'filled'
  @Input() modifier?: LabelModifier = ''
  @Input() leftIcon?: string
  @Input() rightIcon?: string
  @Input() outlinedIcon?: boolean = false
  @Input() text?: string

  getLabelModifier(): string {
    return this.modifier !== '' ? `--${this.modifier}` : ''
  }
}
