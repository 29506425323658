import { CommonModule } from '@angular/common'
import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { IAdminProcessingDialogOptions } from 'src/app/core/interfaces/dialog.interface'


@Component({
  selector: 'admin-processing-dialog-component',
  standalone: true,
  template: `
    {{data.content}} <mat-spinner diameter="16" />
  `,
  styleUrls: ['../../../../assets/admin.scss'],
  styles: [`
    :host {
      display: block;
      padding: 10px 20px;
      text-align: center;
    }

    mat-spinner {
      display: inline-block;
    }
  `],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ],
})
export class AdminProcessingDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAdminProcessingDialogOptions,
    public dialogRef: MatDialogRef<AdminProcessingDialogComponent>
  ) {}
}
