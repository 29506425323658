import { CommonModule } from '@angular/common'
import { Component, Inject, inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { DialogService } from 'src/app/core/services/dialog.service'
import { ConfigService } from 'src/app/core/services/config.service'
import { Subject } from 'rxjs'
import { LanguageService } from 'src/app/core/services/language.service'
import { TextFieldDataInputComponent } from 'src/app/admin/controls/data-input/text-field/text-field.component'
import { MatIconModule } from '@angular/material/icon'
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { get } from 'lodash-es'
import { ButtonModule } from 'src/app/components/button/button.module'
import { IconButtonModule } from 'src/app/components/icon-button/icon-button.module'
import { II18nEntryDialogOptions } from 'src/app/core/interfaces/dialog.interface'
import { TextPreviewInputComponent } from '../../controls/text-preview/text-preview.component'


@Component({
  selector: 'admin-i18n-entry-dialog-component',
  standalone: true,
  template: `
    <div mat-dialog-title class="dialog__title">
      <span>{{data.label}}</span>
      <div class="close-button-wrapper">
        <app-icon-button
          icon-btn-medium
          icon-btn-icon-medium
          matIcon="close"
          (click)="closeDialog('cancel')"
          aria-label="Close dialog"
        ></app-icon-button>
      </div>
    </div>
    <mat-dialog-content class="dialog__content">
      <form [formGroup]="form">
        @for (lang of langService.getAvailableLanguagesForAllDomains(); track lang) {
          <mat-form-field>
            <mat-label>{{lang.adminName}}</mat-label>
            <input
              matInput
              #dataInput
              type="text"
              [formControlName]="lang.code"
            />
          </mat-form-field>
          @if (data.preview) {
            <app-text-preview
              [text]="getValue(form.value, lang.code)"
              [format]="data.preview"
            ></app-text-preview>
          }
        }
      </form>
    </mat-dialog-content>
    <mat-dialog-actions class="dialog__actions">
      <app-button btn-medium btn-primary btn-contained (click)="closeDialog('apply')">Apply</app-button>
    </mat-dialog-actions>
  `,
  styleUrls: ['../../../../assets/admin.scss'],
  styles: [`
    @use "sass:map";
    @import 'src/assets/_colors';
    @import 'src/assets/typography';
    :host {
      display: block;
      width: 500px;
    }

    .dialog {
      &__title {
        @include typography(h5);
        color: map-get($light, text, primary);
        display: flex;
        align-items: center;
        &::before {
          display: none;
        }
      }

      &__content {
        padding-top: 0 !important;
        padding-bottom: 0;
      }

      &__actions {
        padding-top: 0;
      }
    }
  `],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    TextFieldDataInputComponent,
    ButtonModule,
    IconButtonModule,
    TextPreviewInputComponent,
  ],
})
export class AdminI18nEntryDialogComponent implements OnInit {
  window = inject(Window)
  protected submitEventNotifier = new Subject<void>()
  form: FormGroup

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: II18nEntryDialogOptions,
    public dialogRef: MatDialogRef<AdminI18nEntryDialogComponent>,
    protected configService: ConfigService,
    protected langService: LanguageService,
    private fb: FormBuilder,
    private _dialogService: DialogService,
  ) {
    this.form = this.fb.group({})
  }

  ngOnInit(): void {
    this.initializeFormControls()
  }

  initializeFormControls(): void {
    this.langService.getAvailableLanguagesForAllDomains().forEach(lang => {
      const langKey = this.data.key + '.' + lang.code
      this.form.addControl(lang.code, new FormControl(get(this.data.config, langKey)))
    })
  }

  getValue(data: object, key: string): string {
    return get(data, key)
  }

  closeDialog(action: 'cancel' | 'apply'): void {
    if (action !=='apply' && this.form.dirty) {
      this._dialogService.confirmAdmin({
        title: 'Warning: Changes will not be saved without selecting “Apply”.',
        cancelLabel: 'Cancel',
        confirmLabel: 'Confirm',
      }).afterClosed().subscribe(choice => {
        if (choice === 'confirm') {
          this.dialogRef.close()
        }
      })
      return
    }
    this.dialogRef.close(this.form.value)
  }

}
