import { Pipe, PipeTransform, inject } from '@angular/core'
import { LogService } from '../services/log.service'
import { PriceService } from '../services/price.service'
import { CurrencyCode } from '../interfaces/currency.interface'


export interface IAssetPriceData {
  list_price: number
  list_price_native: number
  list_price_native_currency: string
}

@Pipe({
  name: 'assetPrice',
  standalone: true,
})
export class AssetPricePipe implements PipeTransform {
  private _logger = inject(LogService)
  private _priceService = inject(PriceService)

  transform(value: any, toCurrencyCode: any): any {
    const {
      list_price,
      list_price_native,
      list_price_native_currency,
    } = value as IAssetPriceData

    if (!list_price || !list_price_native || !list_price_native_currency) {
      this._logger.error('Tried converting invalid asset data: ', value)
      return 'n/a'
    }

    const convertedPrice = this._priceService.calculatePriceForSelectedCurrency(
      list_price,
      list_price_native,
      list_price_native_currency as CurrencyCode,
      toCurrencyCode,
    )

    const currencyOptions = {
      style: 'currency',
      currency: convertedPrice.code,
      currencyDisplay: 'narrowSymbol',
      maximumFractionDigits: 0
    } as Intl.NumberFormatOptions

    return Intl.NumberFormat( undefined, currencyOptions).format(convertedPrice.price)
  }
}
