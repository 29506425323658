import { IJsonLogic } from './json-logic.interface'

export interface IFilterCommands {
  and: (...commands: any) => IJsonLogic
  or: (...commands: any) => IJsonLogic
  inArray: (...commands: any) => IJsonLogic
  variable: (key: string) => IJsonLogic
  equals: (...commands: any) => IJsonLogic
  doesNotEqual: (...commands: any) => IJsonLogic
  lt: (...commands: any) => IJsonLogic
  lte: (...commands: any) => IJsonLogic
  gt: (...commands: any) => IJsonLogic
  gte: (...commands: any) => IJsonLogic
  is: IIsFilters
  not: INotFilters
}

export interface IIsFilters {
  featured: IJsonLogic
  listed: IJsonLogic
  equipment: (id: string) => IJsonLogic
}

export interface INotFilters {
  featured: IJsonLogic
  equipment: (id: string) => IJsonLogic
}

export interface IHasFilters {
  price: IJsonLogic
}

export interface IFilter {
  id: FilterId
  state: any
}

export enum FilterId {
  CatSubcat = 'CatSubcat',
  MakeModel = 'MakeModel',
  PriceRange = 'PriceRange',
  MilesRange = 'MilesRange',
  HoursRange = 'HoursRange',
  Location = 'Location',
  Year = 'Year',
  State = 'State',
}

export interface IFilterItem {
  value: string
  children?: IFilterItem[]
}

export interface IFilterSettings {
  [key: string]: IFilterItem[]
}

export interface IGetFiltersResponse {
  results: IFilterSettings[]
}
