import { CommonModule } from '@angular/common'
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core'
import { FilterDirective } from '../filter.directive'
import { FilterService } from 'src/app/core/services/filter.service'
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox'
import { SearchService } from 'src/app/core/services/search.service'
import { ChipsService } from 'src/app/core/services/chips.service'
import { EventService } from 'src/app/core/services/event.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { filter } from 'rxjs'
import { IJsonLogic } from 'src/app/core/interfaces/json-logic.interface'
import { IFilterItem } from 'src/app/core/interfaces/filter.interface'


interface ISelectedItem {
  value: string
  children?: ISelectedItem[]
}

@Component({
  selector: 'app-drilldown-filter',
  standalone: true,
  template: `
    @for (setting of settings; track setting) {
      <div>
        <mat-checkbox
          type="checkbox"
          (change)="handleParentChange($event, setting.value)"
          [checked]="isParentSelected(setting.value)"
        >{{setting.value}}</mat-checkbox>
        @if (isParentSelected(setting.value) && setting.children) {
          <div class="children-container">
            <div class="child">
              <div class="child-title">{{childTitle}}</div>
              @for (childSetting of setting.children; track childSetting) {
                <mat-checkbox
                  type="checkbox"
                  (change)="handleChildChange($event, setting.value, childSetting.value)"
                  [checked]="isChildSelected(setting.value, childSetting.value)"
                  [disabled]="!isParentSelected(setting.value)"
                >{{childSetting.value}}</mat-checkbox>
              }
            </div>
          </div>
        }
      </div>
    }
  `,
  styles: [`
    @use 'sass:map';
    @import 'src/assets/_typography';
    @import 'src/assets/_colors';

    .child {
      margin-left: 20px;
    }
    .child-title {
      color: var(--light-text-primary, #191919);
      @include typography(subtitle-1-medium);
      margin-bottom: 10px;
    }
    .children-container {
      margin-bottom: 16px;
    }
    :host {
      --mdc-checkbox-selected-icon-color: var(--chip-background-color, #{map-get($light, primary, main)});
      --mdc-checkbox-selected-hover-icon-color: var(--chip-background-color, #{map-get($light, primary, main)});
      --mdc-checkbox-selected-focus-icon-color: var(--chip-background-color, #{map-get($light, primary, main)});
      --mdc-checkbox-selected-pressed-icon-color: var(--chip-background-color, #{map-get($light, primary, main)});
      --mdc-checkbox-selected-focus-state-layer-color: var(--chip-background-color, #{map-get($light, primary, main)});
      --mdc-checkbox-selected-hover-state-layer-color: var(--chip-background-color, #{map-get($light, primary, main)});
      --mdc-checkbox-selected-pressed-state-layer-color: var(--chip-background-color, #{map-get($light, primary, main)});

      ::ng-deep .mdc-checkbox {
        --mdc-checkbox-state-layer-size: 18px;
      }

      .mat-mdc-checkbox {
        margin-bottom: 16px;
        width: 100%;
      }

      ::ng-deep .mdc-form-field > label {
        padding-left: 6px;
      }
    }
  `],
  imports: [
    CommonModule,
    MatCheckboxModule,
  ],
})
export class DrilldownFilterComponent extends FilterDirective implements OnInit {
  private _destroyRef = inject(DestroyRef)
  private _filterService = inject(FilterService)
  private _searchService = inject(SearchService)
  private _chipsService = inject(ChipsService)
  private _eventService = inject(EventService)
  @Input() parentId!: string
  @Input() childId!: string
  @Input() childTitle?: string = ''

  private _selectedItems: ISelectedItem[] = []

  ngOnInit(): void {
    this._selectedItems = this._filterService.getFilterState(this.filterId)?.state || []
    DrilldownFilterComponent.fixMissingParents(this._selectedItems, this.settings)
    this.initChips()
  }

  initChips(): void {
    this._selectedItems.forEach(item => {
      this._chipsService.addChip({
        id: item.value,
        label: item.value,
        filterId: this.filterId,
      })
      if (item.children) {
        item.children.forEach(child => {
          this._chipsService.addChip({
            id: child.value,
            label: child.value,
            filterId: this.filterId,
            parentId: item.value,
          })
        })
      }
    })
    this._eventService.chipRemoved$.pipe(
      takeUntilDestroyed(this._destroyRef),
      filter(chip => chip.filterId === this.filterId),
    ).subscribe(chip => {
      if (chip.parentId) {
        this.removeChild(chip.parentId, chip.id)
      } else {
        this.removeParent(chip.id)
      }
    })
    this._eventService.allChipsRemoved$.pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(() => {
      this.clearAll()
    })
  }

  isParentSelected(value: string): boolean {
    return !!this._selectedItems?.find(i => i.value === value)
  }

  isChildSelected(parentValue: string, value: string): boolean {
    return !!this._selectedItems?.find(i => i.value === parentValue)?.children?.find(c => c.value === value)
  }

  handleParentChange(event: MatCheckboxChange, value: string): void {
    if (event.checked) {
      this.addParent(value)
    } else {
      this.removeParent(value)
    }
  }

  addParent(value: string): void {
    this._selectedItems.push({
      value,
    })
    this._chipsService.addChip({
      id: value,
      label: value,
      filterId: this.filterId,
    })
    this.processStateChange()
  }

  removeParent(value: string): void {
    const index = this._selectedItems.findIndex(i => i.value === value)
    if (index >= 0) {
      this._selectedItems[index].children?.forEach(child => {
        this._chipsService.removeChip(child.value)
      })
      this._selectedItems.splice(index, 1)
      this._chipsService.removeChip(value)
      this.processStateChange()
    }
  }

  handleChildChange(event: MatCheckboxChange, parentValue: string, value: string): void {
    const parent = this._selectedItems.find(i => i.value === parentValue)
    if (!parent) {
      return
    }
    if (event.checked) {
      this.addChild(parent, value)
    } else {
      this.removeChild(parent, value)
    }
  }

  addChild(parent: ISelectedItem, value: string): void {
    if (!parent.children) {
      parent.children = []
    }
    parent.children.push({
      value,
    })
    this._chipsService.addChip({
      id: value,
      label: value,
      filterId: this.filterId,
      parentId: parent.value,
    })
    this.processStateChange()
  }

  removeChild(parent: ISelectedItem | string, value: string): void {
    if (typeof parent === 'string') {
      parent = this._selectedItems.find(i => i.value === parent) as ISelectedItem
      if (!parent) {
        return
      }
    }
    const index = (parent.children || []).findIndex(i => i.value === value)
    if (index >= 0) {
      (parent.children || []).splice(index, 1)
      this._chipsService.removeChip(value)
    }
    if (parent.children?.length === 0) {
      delete parent.children
    }
    this.processStateChange()
  }

  clearAll(): void {
    this._selectedItems = []
    this.processStateChange()
  }

  processStateChange(): void {
    this._searchService.handleFilterUpdated(
      this.filterId,
      this._selectedItems.length > 0 ? this._selectedItems : null,
    )
  }

  static buildFilters(
    filterService: FilterService,
    selectedItems: ISelectedItem[],
    settingsId: string,
    parentId: string,
    childId?: string,
  ): IJsonLogic {
    DrilldownFilterComponent.fixMissingParents(selectedItems, filterService.filterSettings[settingsId])
    const filters: IJsonLogic = filterService.build(({ and, or, inArray, equals, variable }) => {
      const allFilters: IJsonLogic[] = []
      selectedItems.forEach(parentItem => {
        const filtersForGroup = []
        const parentFilter = equals(
          variable(parentId),
          parentItem.value,
        )
        filtersForGroup.push(parentFilter)
        if (parentItem.children && childId) {
          const childFilter = inArray(
            variable(childId),
            parentItem.children.map(child => child.value),
          )
          filtersForGroup.push(childFilter)
        }
        if (filtersForGroup.length > 1) {
          allFilters.push(
            and(...filtersForGroup)
          )
        } else {
          allFilters.push(filtersForGroup[0])
        }
      })
      return or(...allFilters)
    })
    return filters
  }

  // if a filter has a child but not a parent, find and add the parent
  // (happens when loading a redirect link where the parent isn't included, e.g. /equipment/search/subcategory/0-114 HP Crawler Dozers)
  static fixMissingParents(selectedItems: ISelectedItem[], settings: IFilterItem[]): void {
    selectedItems.forEach(item => {
      if (!item.value) {
        const child = item.children?.[0]
        const parent = settings.find(s => s.children?.find(c => c.value === child?.value))
        if (parent) {
          item.value = parent.value
        }
      }
    })
  }
}
