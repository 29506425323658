import { Injectable, inject } from '@angular/core'
import { LogService } from './log.service'
import { StorageService } from './storage.service'


@Injectable()
export class HistoryService {

  private _logger = inject(LogService)
  private _storageService = inject(StorageService)
  private _visitedAssets: string[] = []
  private _maxVisitedAssets: number = 11

  get visitedAssets(): string[] {
    return this._visitedAssets.filter(a => !!a)
  }

  init(): void {
    try {
      this._visitedAssets = JSON.parse(this._storageService.getItem('visitedAssets') || '[]')
    } catch (e) {
      this._logger.error('Unable to parse visited assets from local storage: ', e)
      this._visitedAssets = []
    }
  }

  addVisitedAsset(assetId: string): void {
    if (!assetId) {
      return
    }
    const index = this._visitedAssets.findIndex(id => id === assetId)
    if (index > -1) {
      this._visitedAssets.splice(index, 1)
    }
    this._visitedAssets.push(assetId)
    this._visitedAssets.length = Math.min(this._visitedAssets.length, this._maxVisitedAssets)
    this._storageService.setItem('visitedAssets', JSON.stringify(this._visitedAssets))
  }
}
