import { Injectable, WritableSignal, inject, signal } from '@angular/core'
import { EventService } from './event.service'
import { IChip } from '../interfaces/chip.interface'

@Injectable({
  providedIn: 'root'
})
export class ChipsService {
  private _eventService = inject(EventService)

  chips: WritableSignal<IChip[]> = signal([])

  addChip(chip: IChip): void {
    this.chips().push(chip)
  }

  // should only notify when a chip is manually removed by clicking it
  removeChip(chipId: string, notify: boolean = false): void {
    const index = this.chips().findIndex(chip => chip.id === chipId)
    if (index !== -1) {
      const removedChip = this.chips().splice(index, 1)[0]
      if (notify) {
        this._eventService.notifyChipRemoved(removedChip)
      }
    }
  }

  clearChips(): void {
    this.chips.set([])
    this._eventService.notifyAllChipsRemoved()
  }
}
