import { Injectable, inject, DestroyRef, WritableSignal, signal } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { getAuth, signInWithCustomToken } from '@angular/fire/auth'
import { EnvironmentService } from './environment.service'
import { LogService } from './log.service'
import { FetchService } from './fetch.service'
import { ClientService } from './client.service'
import { ApiRequestService } from './api-request.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { lastValueFrom } from 'rxjs'
import { Firestore, doc, getFirestore, onSnapshot, updateDoc } from '@angular/fire/firestore'
import { environment } from 'src/environments/environment'
import { initializeApp } from '@angular/fire/app'
import { AbstractControl, FormRecord, ValidationErrors, ValidatorFn } from '@angular/forms'
import { IDNSFirestoreTokenResponse, IDNSSaveResponse, IDomainStatusResponse, ISaveDomainResponse } from '../interfaces/dns.interface'

export enum DomainRequestStatus {
  InProgress = 'IN_PROGRESS',
  Succeded = 'SUCCEEDED',
  Failed = 'FAILED',
  Acknowledged = 'ACKNOWLEDGED',
}

export enum DomainStatus {
  OK = 'OK',
  Error = 'ERROR',
  Missing = 'MISSING',
}

export interface IDomainTransferStatus {
  status: DomainRequestStatus | undefined
  statusPerDomain: Record<string, { status: DomainStatus, reason?: string }>,
  activeDomain: string,
  domains: Array<string>,
}

@Injectable({
  providedIn: 'root',
})
export class DnsService {
  private _destroyRef = inject(DestroyRef)
  private _envService = inject(EnvironmentService)
  private _logger = inject(LogService)
  private _fetchService = inject(FetchService)
  private _apiRequestService = inject(ApiRequestService)
  private _clientService = inject(ClientService)

  protected client_id = this._clientService.client().id
  protected client_code = this._clientService.client().code

  domains = signal<string[]>([])

  async saveDomains(domains: Array<string>): Promise<IDNSSaveResponse> {
    for (const domain of domains) {
      if (!domain || !this._isValidDomain(domain)) {
        this._logger.error('Domain is not valid', domain)
        return Promise.resolve({ success: false })
      }
    }

    this._logger.debug('Saving domains', domains)
    if (this._envService.get().useMock) {
      return Promise.resolve({ success: true })
    }

    const requestOptions = await this._apiRequestService.buildRequestOptions({ headers: { authorized: true } })
    const endpoint = this._apiRequestService.buildEndpoint('admin/domain/webshop')
    const payload = { domains, send_email: true, new_primary_domain: domains[0] }
    const source$ = this._fetchService.putRequest<ISaveDomainResponse>(endpoint, payload, requestOptions).pipe(takeUntilDestroyed(this._destroyRef))
    const response = await lastValueFrom(source$)

    if (response instanceof HttpErrorResponse) {
      this._logger.error('Error saving domain', response)
      return Promise.reject({ success: false })
    }

    this._clientService.updateClient({ domain_status_request_id: response.request_id }).catch(e => this._logger.error('Error updating client', e))

    return Promise.resolve({ success: true })
  }

  private _isValidDomain(domain: string): boolean {
    try {
      new URL(`https://${domain}`)
      return true
    } catch (e) {
      return false
    }
  }

  private async initializeFirebaseApp(): Promise<{ db: Firestore }> {
    const requestOptions = await this._apiRequestService.buildRequestOptions({ headers: { authorized: true } })
    const endpoint = this._apiRequestService.buildEndpoint('admin/getFirestoreToken')
    const source$ = this._fetchService.postRequest<IDNSFirestoreTokenResponse>(endpoint, undefined, requestOptions).pipe(takeUntilDestroyed(this._destroyRef))
    const response = await lastValueFrom(source$)
    if (response instanceof HttpErrorResponse) {
      this._logger.error('Error getting domain status', response)
      throw response
    }
    const token = response.token
    const userApp = initializeApp(environment.firebaseConfig)
    await signInWithCustomToken(getAuth(userApp), token)
    const db = getFirestore(userApp)

    return { db }
  }

  createCcTLDValidator(inputForm: FormRecord): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value ?? ''

      if (!value) {
        return null
      }

      const excludedDomains = environment.excludedTLDValidationDomains

      if (excludedDomains.some(domain => value.endsWith(domain))) {
        return null
      }

      const postfixDomain = (value as string).split('.').pop()
      const currentPostfixDomains = Object.values(inputForm.controls)
        .filter(formControl => formControl !== control)
        .map(control => (control.value as string ?? '').split('.').pop())

      return currentPostfixDomains.indexOf(postfixDomain) >= 0 ? { ccTLD: true } : null
    }
  }

  async getDomainStatusList(domainTransferStatus: WritableSignal<IDomainTransferStatus>): Promise<void> {
    try {
      const { db } = await this.initializeFirebaseApp()
      const docPath = `/env/${environment.firebaseEnv}/domain-service/creation-status/${this._clientService.getClientCode().toLocaleLowerCase()}/${this._clientService.getClientDomainStatusRequestId()}`
      onSnapshot(
        doc(db, docPath),
        (domainStatusDoc) => {
          const data = domainStatusDoc.data()
          domainTransferStatus.set({
            status: data?.status,
            statusPerDomain: data?.status_per_domain ?? {},
            activeDomain: data?.client_domain,
            domains: data?.client_domains,
          })
        }
      )

    } catch (error) {
      this._logger.error('Error getting domain status', error as HttpErrorResponse)
    }
  }

  async loadDomains(): Promise<void> {
    const status = await this.getDNSStatus()
    this.domains.set(status.domains)
  }

  async getDNSStatus(): Promise<IDomainStatusResponse> {
    const requestOptions = await this._apiRequestService.buildRequestOptions({ headers: { authorized: true } })
    const endpoint = this._apiRequestService.buildEndpoint('admin/domain/webshop')
    const source$ = this._fetchService.getRequest<IDomainStatusResponse>(endpoint, undefined, requestOptions).pipe(takeUntilDestroyed(this._destroyRef))
    const response = await lastValueFrom(source$)
    if (response instanceof HttpErrorResponse) {
      this._logger.error('Error getting domains lock status', response)
      throw response
    }
    return response
  }

  async acknowledgeDomainStatus(): Promise<void> {
    try {
      const { db } = await this.initializeFirebaseApp()
      const docPath = `/env/${environment.firebaseEnv}/domain-service/creation-status/${this._clientService.getClientCode().toLocaleLowerCase()}/${this._clientService.getClientDomainStatusRequestId()}`
      updateDoc(doc(db, docPath), { status: 'ACKNOWLEDGED' })
    } catch (error) {
      this._logger.error('Error acknowledging domain status', error as HttpErrorResponse)
    }
  }
}
