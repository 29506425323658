<div class="file-upload__wrapper"
  [class.dragging]="isDragging"
  [class.has-multiple-files]="show_preview() && files.length > 1">
  <input
    #dataInput
    class="file-upload__input"
    type="file"
    [accept]="accept()"
    (change)="onFileSelected($event)"
    (dragenter)="handleDragEnter()"
    (focus)="handleDragEnter()"
    (mousedown)="handleDragEnter()"
    (dragleave)="handleDragLeave()"
    (blur)="handleDragLeave()"
    (mouseup)="handleDragLeave()"
    (drop)="handleDragLeave()"
    [hidden]="show_preview() && files.length"
    [multiple]="multiple()"
  />
  @if(files.length === 0 || show_preview() === false) {
    <div class="file-upload__instructions">
      <p class="subtitle-2" [class.show-media-library]="show_media_library()">
        Drag and drop here or
        <span>
          <app-button btn-small class="Button--primary" (click)="handleAddClick()">
            {{ show_media_library() ? 'Select from Library' : 'Add photos' }}
          </app-button>
        </span>
      </p>
      <p class="caption">JPEG or PNG formats, up to 2MB</p>
    </div>
  } @else {
    @for (file of Array.from(files); track $index) {
      <div class="file-upload__preview" (click)="handleShowMediaLibrary()">
        <div class="file-upload__img-wrapper">
          <img [src]="mediaData()?.urls?.[ImageSizes.Thumbnail]" class="file-upload__img" />
          <div class="file-upload__actions">
            <span class="material-icons file-upload__add" (click)="handleShowMediaLibrary()">edit</span>
            <span class="material-icons file-upload__delete" (click)="handleRemoveClick($index)">close</span>
          </div>
          @if (tempFileLoading[$index]) {
            <div class="file-upload__loading">
              <mat-spinner diameter="30"></mat-spinner>
            </div>
          }
          @if (tempFileErrors[$index]) {
            <div class="file-upload__error">{{tempFileErrors[$index]}}</div>
          }
        </div>
        <div class="file-upload__data">
          <p class="file-upload__name" >{{removeFileHash(mediaData()?.name)}}</p>
          <p #fileUploadDimensions class="file-upload__name" >{{mediaData()?.width}}x{{mediaData()?.height}}</p>
        </div>
      </div>
    }
  }

</div>
<div class="recommendations">{{recommendations()}}</div>