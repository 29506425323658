import { NgModule } from '@angular/core'
import { ButtonComponent } from './button.component'
import { CommonModule } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'
import { RouterModule } from '@angular/router'
import {
  ButtonTextDirective,
  ButtonContainedDirective,
  ButtonLargeDirective,
  ButtonMediumDirective,
  ButtonOutlinedDirective,
  ButtonPrimaryDirective,
  ButtonSecondaryDirective,
  ButtonSmallDirective,
  ButtonXLargeDirective,
  ButtonXSmallDirective,
} from './button.directive'

@NgModule({
  declarations: [
    ButtonComponent,
    ButtonTextDirective,
    ButtonContainedDirective,
    ButtonOutlinedDirective,
    ButtonPrimaryDirective,
    ButtonSecondaryDirective,
    ButtonXSmallDirective,
    ButtonSmallDirective,
    ButtonMediumDirective,
    ButtonLargeDirective,
    ButtonXLargeDirective,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    RouterModule,
  ],
  exports: [
    ButtonComponent,
    ButtonTextDirective,
    ButtonContainedDirective,
    ButtonOutlinedDirective,
    ButtonPrimaryDirective,
    ButtonSecondaryDirective,
    ButtonXSmallDirective,
    ButtonSmallDirective,
    ButtonMediumDirective,
    ButtonLargeDirective,
    ButtonXLargeDirective,
  ],
})
export class ButtonModule { }
