import { HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
import { firstValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { IHeadersOptionsConfig, IRequestOptionsConfig } from '../interfaces/api-request.interface'
import { IRequestOptions } from '../interfaces/fetch.interface'

@Injectable()
export class ApiRequestService {
  constructor(
    private auth: AuthService,
  ) {}

  private getBaseEndpoint(): string {
    return environment.data.api.replace('{client_code}', environment.client)
  }

  buildEndpoint(path: string, queryParams: {[key: string]: string} = {}): string {
    if (!!queryParams && Object.keys(queryParams).length > 0) {
      path += `?${(new URLSearchParams(queryParams)).toString()}`
    }
    return `${this.getBaseEndpoint()}/${path}`
  }

  buildPagination(field: string, direction: string, index: number, size: number) {
    return {
      field,
      direction,
      index,
      size,
    }
  }

  async buildRequestOptions(config: IRequestOptionsConfig): Promise<IRequestOptions> {
    const {
      headers = null,
    } = config
    const requestOptions: IRequestOptions = {}
    if (headers) {
      requestOptions.headers = await this.buildHeaders(headers)
    }
    return requestOptions
  }

  async buildHeaders(config: IHeadersOptionsConfig): Promise<HttpHeaders> {
    const {
      customHeaders = {},
      authorized = false,
    } = config
    const headers: { [key: string]: any } = {}
    if (authorized) {
      const token = await firstValueFrom(this.auth.getAccessTokenSilently())
      headers.Authorization = `Bearer ${token}`
    }
    return new HttpHeaders({
      ...customHeaders,
      ...headers,
    })
  }
}
