import { Component, input } from '@angular/core'
import { CommonModule } from '@angular/common'


@Component({
  selector: 'app-text-preview',
  standalone: true,
  template: `
    <div class="preview">
      <div class="preview__heading">Preview:</div>
      <div class="preview__text">{{format()(text())}}</div>
    </div>
  `,
  styleUrls: ['../../../../assets/admin.scss'],
  styles: [`
    @use "sass:map";
    @import 'src/assets/_colors';
    :host {
      display: block;
    }
    .preview {
      margin: -1rem 0 2rem 1rem;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      &__heading {
        font-size: 0.8em;
      }
      &__text {
        flex-grow: 1;
        width: 93%;
      }
    }
  `],
  imports: [
    CommonModule,
  ],
})
export class TextPreviewInputComponent {
  format = input.required<(text: string) => string>()
  text = input.required<string>()
}
