import { Injectable, WritableSignal, inject, signal, DestroyRef } from '@angular/core'
import { LogService } from './log.service'
import { FetchService } from './fetch.service'
import { ApiRequestService } from './api-request.service'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'
import { HttpErrorResponse } from '@angular/common/http'
import { IApiNewResponse } from 'src/app/core/interfaces/config.interface'
import { IClient } from '../interfaces/client.interface'
import { GlobalDataService } from './global-data.service'

declare global {
  interface Window { clientService: ClientService; }
}

@Injectable()
export class ClientService {
  private destroyRef = inject(DestroyRef)
  private _logger = inject(LogService)
  private _globalData = inject(GlobalDataService)

  client: WritableSignal<IClient> = signal({} as IClient)

  constructor(
    protected apiRequestService: ApiRequestService,
    protected fetchService: FetchService
  ) {
    window.clientService = this
    toObservable(this.client).pipe(
      takeUntilDestroyed(),
    ).subscribe(client => {
      this._globalData.unitOfDistance.set(client.webshop_unit_of_distance)
    })
  }

  load(clientData: IClient): void {
    this._logger.debug(`Loading client data`, clientData)
    this.client.set(clientData)
  }

  getMeterUod(): 'M' | 'K' {
    return this.client().webshop_unit_of_distance === 'kilometers' ? 'K' : 'M'
  }

  getClientCode(): string {
    return this.client().sales_code || ''
  }

  getClientName(): string {
    return this.client().name || ''
  }

  getClient(): IClient {
    return this.client()
  }

  getClientDomainStatusRequestId(): string {
    return this.client().domain_status_request_id
  }

  async updateClient(clientData: Partial<IClient>): Promise<void> {
    const requestOptions = await this.apiRequestService.buildRequestOptions({ headers: { authorized: true } })
    return new Promise((resolve, reject) => {
      this._logger.debug(`Updating client data`, clientData)
      this.client.update(client => ({
        ...client,
        ...clientData,
      }))
      this.fetchService.patchRequest<IApiNewResponse>(`${this.apiRequestService.buildEndpoint('admin/client/webshop')}`, this.client(), requestOptions).pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(response => {
        if (response instanceof HttpErrorResponse) {
          this._logger.error('Unable to save client.  ', response)
          return reject(response.error)
        }
        if (!response.success) {
          this._logger.error('Updating client was not successful.  ', response.reason)
          return reject(response.reason)
        }
        resolve()
      })
    })
  }
}
