
export enum LanguageCode {
  EN_US = 'en-US',
  EN_CA = 'en-CA',
  ES_MX = 'es-MX',
  ES_SP = 'es-SP',
  FR_CA = 'fr-CA',
}

export interface ILanguage {
  name: string
  code: LanguageCode
  adminName?: string
}
