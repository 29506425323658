import { DestroyRef, Injectable, inject } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { LogService } from './log.service'
import { AdminConfirmDialogComponent } from 'src/app/admin/dialog/confirm/admin-confirm-dialog.component'
import { ConfirmDialogComponent } from 'src/app/components/dialog/confirm/confirm-dialog.component'
import { AdminInputDialogComponent } from 'src/app/admin/dialog/input/admin-input-dialog.component'
import { EventService } from './event.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { ContactDialogComponent } from 'src/app/components/dialog/contact/contact-dialog.component'
import { MediaLibraryDialogComponent } from 'src/app/components/dialog/media-library/media-library.component'
import { AdminI18nEntryDialogComponent } from 'src/app/admin/dialog/i18n-entry/admin-i18n-entry-dialog.component'
import { AdminProcessingDialogComponent } from 'src/app/admin/dialog/processing/admin-processing-dialog.component'
import { IAdminProcessingDialogOptions, IConfirmDialogOptions, IContactDialogOptions, II18nEntryDialogOptions, IInputDialogOptions, IMediaLibraryDialogOptions } from '../interfaces/dialog.interface'


@Injectable()
export class DialogService {
  private _destroyRef = inject(DestroyRef)

  constructor(
    public dialog: MatDialog,
    public logger: LogService,
    private _eventService: EventService,
  ) {}

  init(): void {
    this._eventService.contactClicked$.pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(asset => {
      this._contact({
        type: asset ? 'asset' : '',
        title: asset ? $localize`:@@dialogService_ContactSalesDialogTitle:Contact Sales` : $localize`:@@dialogService_ContactUsDialogTitle:Contact Us`,
        asset,
      })
    })
  }

  private _confirm(
    confirmOptions: IConfirmDialogOptions,
    dialogComponent: typeof AdminConfirmDialogComponent | typeof ConfirmDialogComponent,
  ): MatDialogRef<AdminConfirmDialogComponent | ConfirmDialogComponent, any> {
    const {
      title = null,
      content = null,
      cancelLabel = 'Cancel',
      confirmLabel = 'Confirm',
    } = confirmOptions
    return this.dialog.open(dialogComponent, {
      data: {
        title,
        content,
        cancelLabel,
        confirmLabel,
      },
    })
  }

  confirm(confirmOptions: IConfirmDialogOptions): MatDialogRef<ConfirmDialogComponent, any> {
    return this._confirm(confirmOptions, ConfirmDialogComponent)
  }

  confirmAdmin(confirmOptions: IConfirmDialogOptions): MatDialogRef<AdminConfirmDialogComponent, any> {
    return this._confirm(confirmOptions, AdminConfirmDialogComponent)
  }

  private _mediaLibrary(
    mediaLibraryOptions: IMediaLibraryDialogOptions,
  ): MatDialogRef<MediaLibraryDialogComponent, any> {
    const {
      type = '',
      title = 'Media Library',
      panelClass = 'media-library-dialog',
      mode = 'select',
      onSelect = () => {},
    } = mediaLibraryOptions
    this.dialog.closeAll()
    return this.dialog.open(MediaLibraryDialogComponent, {
      data: {
        type,
        title,
        mode,
        onSelect: onSelect,
      },
      panelClass,
      autoFocus: 'input',
    })
  }

  mediaLibrary(mediaLibraryOptions: IMediaLibraryDialogOptions): MatDialogRef<MediaLibraryDialogComponent, any> {
    return this._mediaLibrary(mediaLibraryOptions)
  }

  private _input(
    inputOptions: IInputDialogOptions,
    dialogComponent: typeof AdminInputDialogComponent,
  ): MatDialogRef<AdminInputDialogComponent, any> {
    const {
      title = null,
      inputs = [],
      cancelLabel = 'Cancel',
      confirmLabel = 'Submit',
      validate = () => true,
    } = inputOptions
    return this.dialog.open(dialogComponent, {
      data: {
        title,
        inputs,
        cancelLabel,
        confirmLabel,
        validate,
      }
    })
  }

  inputAdmin(inputOptions: IInputDialogOptions): MatDialogRef<AdminInputDialogComponent, any> {
    return this._input(inputOptions, AdminInputDialogComponent)
  }

  private _contact(
    contactOptions: IContactDialogOptions,
  ): MatDialogRef<ContactDialogComponent, any> {
    const {
      type = '',
      title = $localize`:@@dialogService_ContactUsDialogTitle:Contact Us`,
      asset = null,
      panelClass = 'contact-dialog',
    } = contactOptions
    return this.dialog.open(ContactDialogComponent, {
      data: {
        type,
        title,
        asset,
      },
      panelClass,
      autoFocus: 'input',
    })
  }

  i18nEntry(options: II18nEntryDialogOptions): MatDialogRef<AdminI18nEntryDialogComponent, any> {
    const {
      label,
      key,
      config,
      panelClass = 'i18n-entry-dialog',
      preview,
    } = options
    this.dialog.closeAll()
    return this.dialog.open(AdminI18nEntryDialogComponent, {
      data: {
        label,
        key,
        config,
        preview,
      },
      panelClass,
      autoFocus: 'input',
      disableClose: true,
    })
  }

  adminProcessing(options: IAdminProcessingDialogOptions): MatDialogRef<AdminProcessingDialogComponent, any> {
    const {
      content = 'Please wait...',
      panelClass = 'admin-processing-dialog',
    } = options
    this.dialog.closeAll()
    return this.dialog.open(AdminProcessingDialogComponent, {
      data: {
        content,
      },
      panelClass,
      disableClose: true,
    })
  }
}
