<div mat-dialog-title [class.submission-confirmed]="submitTrue">
    <span>Media Library</span>
    <div class="close-button-wrapper">
      <button mat-icon-button mat-dialog-close aria-label="Close dialog">
        <mat-icon >close</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content [class.is-scroll-locked]="showInspector" (submitSuccess)="onSubmitSuccess()">
    <div class="media-library__wrapper" >
      <div class="media-library__subtitle subtitle-1-medium mb-20">Upload or select an image from your library to continue.</div>
      <div class="media-library__file-upload-wrapper">
        <app-data-file-upload
          [show_preview]="false"
          [show_media_library]="false"
          [multiple]="true"
          (change)="onFileUpload()"
          #fileUploader>
        </app-data-file-upload>
      </div>
      <div class="light-text-secondary mb-20">You currently have {{ images().length }} photo{{ images.length === 1 ? '' : 's' }} in your library.</div>
      <mat-form-field>
        <input
          matInput
          #searchInput
          placeholder="Search images"
          class="media-library__search-input"
          (keyup)="onFindImage($event)"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="onClearSearch()"
          [attr.aria-label]="'Clear search'"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      @if (images().length > 0 && filteredImages().length === 0) {
        <div class="empty-state__text">No images found</div>
      }
      <div class="image-grid__wrapper">
        @for (image of filteredImages(); track image; let idx = $index) {
          <div class="image-grid__item" [class.is-selected]="selected.includes(image)" [class.is-hidden]="image.isHidden">
            <div class="image-grid__item-inner">
              <div class="image-grid__item-image-wrapper">
                <div class="image-grid__item-inspector" (click)="showImageOverlay(image)">
                  <mat-icon>search_icon</mat-icon>
                </div>
                <mat-spinner [class.is-hidden]="image.loaded" diameter="24"></mat-spinner>
                <img
                  [src]="image.urls.thumbnail"
                  class="image-grid__item-image"
                  [class.is-hidden]="!image.loaded"
                  [alt]="image.name"
                  (load)="imageLoaded(idx)"/>
              </div>
              <div class="image-grid__item-info-wrapper">
                <div class="caption image-grid__item-info image-grid__item-name">{{ showFileName(image.name) }}</div>
                <div class="caption image-grid__item-info--light image-grid__item-dimensions">{{ image.width }}x{{ image.height }}</div>
                <div class="caption image-grid__item-info--light image-grid__item-upload-date">{{ image.updatedFormatted }}</div>
                <div class="image-grid__item-actions">
                <app-icon-button
                    icon-btn-small
                    icon-btn-icon-medium
                    matIcon="delete_outline"
                    (click)="deleteImage(image)"
                    class="image-grid__item-action"
                />
                  @if (data.mode === 'select') {
                    @if (selected.includes(image)) {
                      <app-button btn-small btn-contained btn-primary (click)="selectImage(image)" class="is-selected">
                          <mat-icon inline>check</mat-icon> Selected
                      </app-button>
                    } @else {
                      <app-button btn-small btn-text (click)="selectImage(image)">
                          Select
                      </app-button>
                    }
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
    @if (showInspector && inspectedImage) {
        <div class="image-grid__image-inspector" [class.is-visible-flex]="showInspector">
          <div class="image-wrapper">
            <img [src]="inspectedImage.urls.full" [alt]="inspectedImage.name" />
          </div>
          <div class="image-grid__image-inspector-details">
            <div class="subtitle-2 image-grid__item-info">{{ showFileName(inspectedImage.name) }}</div>
            <div class="subtitle-2 image-grid__item-info--light">{{ inspectedImage.width }}x{{ inspectedImage.height }}</div>
            <div class="subtitle-2 image-grid__item-info--light">{{ inspectedImage.updatedFormatted }}</div>
          </div>
        </div>
      }
  </div>
  @if (!submitTrue && !showInspector) {
    <div mat-dialog-actions class="primary-actions">
      <app-button
        btn-large
        btn-contained
        btn-primary
        (click)="onCLickConfirmSelection()"
        [disabled]="selected.length === 0"
      >{{data.mode === 'select' ? 'Confirm Selection' : 'Close'}}</app-button>
    </div>
  }
  @if (showInspector) {
    <div mat-dialog-actions class="inspector-actions">
      <app-button
        btn-large
        btn-outlined
        btn-secondary
        class="back-button"
        (click)="hideImageOverlay()">
          <mat-icon>arrow_back</mat-icon>
          Back
      </app-button>
      @if (data.mode === 'select') {
        <app-button
          btn-large
          btn-contained
          btn-primary
          (click)="selectImage(inspectedImage)"
        >Select Photo</app-button>
      }
    </div>
  }