import { WritableSignal } from '@angular/core'
import { IConfig } from 'src/app/core/interfaces/config.interface'
import { IConversionProcessor } from './processors'

export const processV3toV4: IConversionProcessor = {
  endVersion: '4',
  process(config: WritableSignal<IConfig>): void {
    updateLanguages(config)
  }
}

const updateLanguages = (config: WritableSignal<any>) => {
  if (config().language.available) {
    config().language.options.base.available = config().language.available
    delete config().language.available
  }
  if (config().language.default) {
    config().language.options.base.default = config().language.default
    delete config().language.default
  }
  delete config().language.enabled
  delete config().language.data
  delete config().language.template
}
