@if (href) {
  <a
    mat-button
    [disabled]="disabled"
    class="Button"
    [class.Button--xsmall]="themeSize === ButtonThemeSize.XSmall"
    [class.Button--small]="themeSize === ButtonThemeSize.Small"
    [class.Button--medium]="themeSize === ButtonThemeSize.Medium"
    [class.Button--large]="themeSize === ButtonThemeSize.Large"
    [class.Button--xlarge]="themeSize === ButtonThemeSize.XLarge"
    [class.Button--primary]="themeStyle === ButtonThemeStyle.Primary"
    [class.Button--secondary]="themeStyle === ButtonThemeStyle.Secondary"
    [class.mat-mdc-basic-button]="themeType === ButtonThemeType.Text"
    [class.mat-mdc-raised-button]="themeType === ButtonThemeType.Contained"
    [class.mat-mdc-outlined-button]="themeType === ButtonThemeType.Outlined"
    (click)="onClick()"
    [href]="href"
    [style]="styleOverrides"
    [attr.aria-label]="ariaLabel"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
} @else {
  <button
    mat-button
    [disabled]="disabled"
    class="Button"
    [class.Button--xsmall]="themeSize === ButtonThemeSize.XSmall"
    [class.Button--small]="themeSize === ButtonThemeSize.Small"
    [class.Button--medium]="themeSize === ButtonThemeSize.Medium"
    [class.Button--large]="themeSize === ButtonThemeSize.Large"
    [class.Button--xlarge]="themeSize === ButtonThemeSize.XLarge"
    [class.Button--primary]="themeStyle === ButtonThemeStyle.Primary"
    [class.Button--secondary]="themeStyle === ButtonThemeStyle.Secondary"
    [class.mat-mdc-basic-button]="themeType === ButtonThemeType.Text"
    [class.mat-mdc-raised-button]="themeType === ButtonThemeType.Contained"
    [class.mat-mdc-outlined-button]="themeType === ButtonThemeType.Outlined"
    [style]="styleOverrides"
    [attr.aria-label]="ariaLabel"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
}
<ng-template #content>
  <div class="content-wrapper">
    <ng-content />
  </div>
</ng-template>