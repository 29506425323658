/* eslint-disable no-console */
import { Injectable } from '@angular/core'
import { captureException } from '@sentry/browser'

export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6
}

@Injectable()
export class LogService {
  level: LogLevel = LogLevel.Off
  logLevelText = {
    [LogLevel.All]: '[TRACE]',
    [LogLevel.Debug]: '[DEBUG]',
    [LogLevel.Info]: '[INFO]',
    [LogLevel.Warn]: '[WARN]',
    [LogLevel.Error]: '[ERROR]',
    [LogLevel.Fatal]: '[FATAL]',
    [LogLevel.Off]: '',
  }

  init(level: LogLevel): void {
    this.level = level
  }

  debug(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, optionalParams)
  }

  info(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams)
  }

  warn(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Warn, optionalParams)
  }

  error(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Error, optionalParams)
  }

  fatal(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Fatal, optionalParams)
  }

  log(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.All, optionalParams)
  }

  private writeToLog(msg: string, level: LogLevel, params: any[]) {
    if (this.shouldLog(level)) {
      if (level === LogLevel.Error || level === LogLevel.Fatal) {
        console.error(this.getLogLevelText(level), msg, ...params)
        captureException(new Error(msg), ...params)
      } else {
        console.log(this.getLogLevelText(level), msg, ...params)
      }
    }
  }

  private getLogLevelText(level: LogLevel): string {
    return this.logLevelText[level]
  }

  private shouldLog(level: LogLevel): boolean {
    let ret: boolean = false
    if ((level >= this.level && level !== LogLevel.Off) || this.level === LogLevel.All) {
      ret = true
    }
    return ret
  }

}
