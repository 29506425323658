import { Injectable, WritableSignal } from '@angular/core'
import { ConfigService } from './config.service'
import { IConfig } from 'src/app/core/interfaces/config.interface'
import { LogService } from './log.service'
import { conversionProcessors, currentConfigVersion } from '../versions/processors'
import { IConversionResult } from '../interfaces/config-version.interface'

@Injectable()
export class ConfigVersionService {
  private _sanityMax: number = 200 // prevent infinite loops
  private _sanityTotal: number = 0

  constructor(
    private logger: LogService,
    private configService: ConfigService,
  ) {}

  get currentVersion(): string {
    return currentConfigVersion
  }

  tryUpdate(config: WritableSignal<IConfig>): void {
    this.logger.info(`Checking config version.  Latest: [${this.currentVersion}] vs Loaded: [${config().version?.trim()}]`)
    if (config().version?.trim() === this.currentVersion) {
      // no update needed
      this.logger.info('No config update needed.')
      return
    }
    this._sanityTotal++
    if (this._sanityTotal > this._sanityMax) {
      this.logger.warn('Sanity max reached when trying to update a config to latest version.')
      return
    }
    const result = this.update(config)
    if (result.success) {
      this.tryUpdate(config)
    }
  }

  update(config: WritableSignal<IConfig>): IConversionResult {
    const startVersion = config().version
    const result: IConversionResult = {
      success: false,
      startVersion,
      endVersion: startVersion,
    }
    if (!Object.keys(conversionProcessors).includes(startVersion)) {
      this.logger.warn(`Unable to update config from version [${startVersion}]`)
      return result
    }
    const processor = conversionProcessors[startVersion]
    const endVersion = processor.endVersion.trim()
    try {
      if (endVersion.trim() === '' || endVersion === startVersion) {
        throw new Error(`Updating from [${startVersion}] to [${endVersion}] returned an invalid endVersion!`)
      }
      processor.process(config, this.logger)
      config().version = endVersion
      result.success = true
      result.endVersion = endVersion
      this.configService.markAsDirty()
      this.logger.info(`Updated config from [${startVersion}] to [${endVersion}]`)
    } catch (e) {
      this.logger.error(`Failed to update config from [${startVersion}] to [${endVersion}]! `, e)
    }
    return result
  }
}
