import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from '@auth0/auth0-angular'
import { maintenanceGuard } from './core/guards/maintenance.guard'
import { userInitGuard } from './core/guards/user-init.guard'


export class RoutePaths {
  public static readonly Details = '/equipment/details/'
  public static readonly Search = '/equipment/'
  public static readonly LegacySearch = 'equipment/search'
  public static readonly Maintenance = '/maintenance'
  public static readonly Admin = '/admin'
}

const removeStartAndEndSlashes = (path: string) => path.replace(/^\/|\/$/g, '')

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    canActivate: [maintenanceGuard, userInitGuard],
    data: {
      module: 'home',
    },
  },
  {
    path: removeStartAndEndSlashes(RoutePaths.Details),
    loadChildren: () => import('./pages/details/details.module').then(m => m.DetailsModule),
    canActivate: [maintenanceGuard, userInitGuard],
    data: {
      module: 'details',
    },
  },
  {
    path: RoutePaths.LegacySearch,
    redirectTo: 'equipment',
    pathMatch: 'prefix'
  },
  {
    path: removeStartAndEndSlashes(RoutePaths.Search),
    loadChildren: () => import('./pages/search/search-page.module').then(m => m.SearchPageModule),
    canActivate: [maintenanceGuard, userInitGuard],
    data: {
      module: 'search',
    },
  },
  {
    path: removeStartAndEndSlashes(RoutePaths.Admin),
    loadChildren: () => import('./pages/admin/admin-page.module').then(m => m.AdminModule),
    canActivate: [maintenanceGuard, AuthGuard],
    data: {
      module: 'admin',
    },
  },
  {
    path: removeStartAndEndSlashes(RoutePaths.Maintenance),
    loadChildren: () => import('./pages/maintenance/maintenance.module').then(m => m.MaintenanceModule),
    canActivate: [maintenanceGuard, userInitGuard],
    data: {
      module: 'maintenance',
    },
  },
  {
    path: '**',
    loadChildren: () => import('./pages/page404/page404.module').then(m => m.Page404Module),
    canActivate: [maintenanceGuard, userInitGuard],
    data: {
      module: 'page404',
    },
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
