import { Injectable, WritableSignal, signal } from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
import { EnvironmentService } from './environment.service'
import { IAsset } from '../interfaces/asset.interface'

@Injectable()
export class AdminService {
  initialized = false
  isAuthenticated = false
  adminHidden = signal(false)
  mobilePreviewMode = signal(false)
  mobilePreviewWidth = signal(375)
  loadedAsset: WritableSignal<IAsset | undefined> = signal(undefined)

  constructor(
    private authService: AuthService,
    private environmentService: EnvironmentService,
  ) {}

  init(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        if (this.environmentService.get().useMock && this.environmentService.get().useMockAdmin) {
          this.setup(true)
          resolve()
        }
        if (this.initialized) {
          return
        }

        this.authService.isAuthenticated$.subscribe(
          (auth) => {
            this.setup(auth)
            resolve()
          }
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  setup(auth: boolean): void {
    this.initialized = true
    this.isAuthenticated = auth
  }

  disableAdmin(): void {
    this.isAuthenticated = false
  }

  isAdminEnabled(): boolean {
    return this.isAuthenticated
  }

  toggleAdminHidden() {
    this.adminHidden.set(!this.adminHidden())
  }

  toggleMobilePreviewMode() {
    this.mobilePreviewMode.set(!this.mobilePreviewMode())
  }
}
