import { DestroyRef, Injectable, WritableSignal, inject, signal } from '@angular/core'
import { FetchService } from './fetch.service'
import { ApiRequestService } from './api-request.service'
import { LogService } from './log.service'
import { EnvironmentService } from './environment.service'
import { lastValueFrom } from 'rxjs'
import { HttpErrorResponse } from '@angular/common/http'
import { MockService } from './mock.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { IAdminGetKeysResponse, IKeys, IKeysSaveRequest, IKeysSaveResponse, IUserGetKeysResponse } from '../interfaces/keys.interface'

declare global {
  interface Window { keysService: KeysService; }
}

@Injectable()
export class KeysService {
  private _destroyRef = inject(DestroyRef)
  private _logger = inject(LogService)
  private _fetchService = inject(FetchService)
  private _apiRequestService = inject(ApiRequestService)
  private _envService = inject(EnvironmentService)
  private _mockService = inject(MockService)

  constructor() {
    window.keysService = this
  }

  keys: WritableSignal<IKeys> = signal({
    heap_key: '',
    recaptcha_site_key: '',
    google_webfont_key: '',
    google_tag_manager: '',
    google_analytics: [],
    recaptcha_secret_key: false,
    auth0_id: false,
    auth0_secret: false,
    send_leads_to: false,
  })

  async saveKeys(keys: IKeysSaveRequest): Promise<IKeysSaveResponse | HttpErrorResponse> {
    this._logger.debug(`KeysService.saveKeys() keys: `, keys)
    if (this._envService.get().useMock) {
      return Promise.resolve({ success: true })
    }
    const requestOptions = await this._apiRequestService.buildRequestOptions({ headers: { authorized: true } })
    const endpoint = this._apiRequestService.buildEndpoint('admin/secrets/webshop')
    const source$ = this._fetchService.patchRequest<IKeysSaveResponse>(endpoint, keys, requestOptions)
    return lastValueFrom(source$)
  }

  async loadForAdmin(): Promise<void> {
    this._logger.debug(`KeysService.loadForAdmin()`)
    if (this._envService.get().useMock) {
      this.keys.set(this._mockService.getMockKeys())
      return Promise.resolve()
    }
    const requestOptions = await this._apiRequestService.buildRequestOptions({ headers: { authorized: true } })
    const endpoint = `${this._apiRequestService.buildEndpoint('admin/secrets/webshop')}`
    const source$ = this._fetchService.getRequest<IAdminGetKeysResponse>(endpoint, undefined, requestOptions).pipe(takeUntilDestroyed(this._destroyRef))
    const response = await lastValueFrom(source$)
    if (response instanceof HttpErrorResponse) {
      this._logger.error('Unable to load keys for admin.  ', response)
      return Promise.reject()
    }
    this.keys.set({
      ...response.public_secrets,
      ...response.private_secrets,
    })
    this._logger.debug(`Admin keys loaded: `, this.keys())
    return Promise.resolve()
  }

  async loadForUser(): Promise<void> {
    this._logger.debug(`KeysService.loadForUser()`)
    if (this._envService.get().useMock) {
      this.keys.set(this._mockService.getMockKeys())
      return Promise.resolve()
    }
    const endpoint = `${this._apiRequestService.buildEndpoint('configuration/secrets/webshop')}`
    const source$ = this._fetchService.getRequest<IUserGetKeysResponse>(endpoint, undefined).pipe(takeUntilDestroyed(this._destroyRef))
    const response = await lastValueFrom(source$)
    if (response instanceof HttpErrorResponse) {
      this._logger.error('Unable to load keys for user.  ', response)
      return Promise.reject()
    }
    this.keys.set(response.public_secrets)
    this._logger.debug(`User keys loaded: `, this.keys())
    return Promise.resolve()
  }

  loadForEmbeddedUser(): void {
    this._logger.debug(`KeysService.loadForEmbeddedUser()`)
    this.keys.set(window.parent.keysService.keys())
  }
}
