import { Injectable } from '@angular/core'
import { IAsset } from '../interfaces/asset.interface'
import { IBadgeSetting } from '../interfaces/badge.interface'

@Injectable()
export class BadgeService {
  public static readonly availableBadges: IBadgeSetting[] = [
    {
      key: 'is_featured',
      icon: 'sell',
      label: $localize`:@@badge_FeaturedLabel:Featured`,
    },
  ]

  getBadgesForData(data: IAsset | undefined): IBadgeSetting[] {
    const badges: IBadgeSetting[] = []
    if (data === undefined) {
      return badges
    }
    BadgeService.availableBadges
      .filter(badge => data[badge.key] === true)
      .forEach(badge => {
        badges.push(badge)
      })
    return badges
  }
}
