import { IAsset, IAssetCategory } from '../interfaces/asset.interface'

export const browseByCategoryData: IAssetCategory[] = [
  { category_id: 1, category: 'Agricultural Equipment', image: '41f062c0-d53b-190e-28f9-e9cc0d885d47', list_price: [1, 100], quantity: 23 },
  { category_id: 2, category: 'Bucket Trucks', image: 'fd78b3e3-5339-7e0f-e8c8-3c5f83570533', list_price: [20, 2000], quantity: 5 },
  { category_id: 3, category: 'Dump Trucks', image: 'a49e7ec5-9e23-346c-e842-58803297ee33', list_price: [3, 300], quantity: 657 },
  { category_id: 4, category: 'Cold Planers', image: 'f66b0e8e-c96c-19e0-7b2a-64370f0e3b6e', list_price: [40, 4000], quantity: 22 },
  { category_id: 5, category: 'Compactors', image: null, list_price: [5, 500], quantity: 68 },
  { category_id: 6, category: 'Cranes', image: 'f66b0e8e-c96c-19e0-7b2a-64370f0e3b6e', list_price: [60, 6000], quantity: 953 },
  { category_id: 7, category: 'Excavators', image: 'f66b0e8e-c96c-19e0-7b2a-64370f0e3b6e', list_price: [7, 700], quantity: 44 },
  { category_id: 8, category: 'Forklifts', image: 'f66b0e8e-c96c-19e0-7b2a-64370f0e3b6e', list_price: [80, 8000], quantity: 78 },
]

export const buildAssets = (count: number = 3): IAsset[] => {
  const assets: IAsset[] = []
  for (let i = 0; i < count; i++) {
    const asset: IAsset = {
      equipment_number: `eq_number_${i}`,
      display_equipment_number: `display_eq_number_${i}`,
      serial_number: `serial_number_${i}`,
      detailed_description: `description ${i}`,
      just_listed: i % 2 ? true : false,
      listing_date: new Date().toString(),
      list_price: 1234 * (i + 1),
      list_price_native: 1234 * (i + 1),
      list_price_rfx_date: new Date().toString(),
      list_price_native_currency: 'USD',
      is_list_price_hidden: i % 2 ? true : false,
      currency_type: 'USD',
      compare_at_price_multiplier: 1.2,
      lowest_monthly_payment: 1234 * (i + 1) / 10,
      lowest_monthly_payment_rfx_date: new Date().toString(),
      category_id: i % 2 ? 1 : 2,
      subcategory_id: i % 2 ? 1 : 2,
      filter_category: i % 2 ? 'Excavators' : 'Telehandlers',
      filter_sub_category: i % 2 ? '25,000-44,999 Lb Excavators' : '7,000-9,999 Lb Telehandlers',
      category: i % 2 ? 'Excavators' : 'Telehandlers',
      sub_category: i % 2 ? '25,000-44,999 Lb Excavators' : '7,000-9,999 Lb Telehandlers',
      branch_address: i % 2 ? '123 Main St' : '456 Main St',
      branch_zipcode: i % 2 ? '12345' : '54321',
      branch_city: i % 2 ? 'Los Angeles' : 'Charlotte',
      branch_state: i % 2 ? 'CA' : 'NC',
      branch_phone: i % 2 ? '1234567890' : '0987654321',
      year: 2023 - i,
      make: i % 2 ? 'Case' : 'JCB',
      model: i % 2 ? 'CX130C' : 'JS220',
      meter_hours: 1234 * (i + 1),
      meter_miles_precise: 3210 * (i + 1),
      is_featured: i % 2 ? false : true,
      is_warranty_eligible:  i % 3 ? false : true,
      is_popular:  i % 4 ? false : true,
      is_low_hours:  i % 5 ? false : true,
      is_financing_eligible:  i % 6 ? false : true,
      image: i % 2 ? 'b2bc8a03-6b1f-e227-e22e-51ec4e378421' : '1a536625-20c3-f99d-9ce1-27f5e94eef54',
      images: i % 2 ? ['b2bc8a03-6b1f-e227-e22e-51ec4e378421'] : ['1a536625-20c3-f99d-9ce1-27f5e94eef54', 'b2bc8a03-6b1f-e227-e22e-51ec4e378421', 'a49e7ec5-9e23-346c-e842-58803297ee33'],
    }
    assets.push(asset)
  }
  return assets
}
