import { CommonModule } from '@angular/common'
import { Component, HostBinding, Inject, inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { ContactFormComponent } from '../../contact-form/contact-form.component'
import { ConfigService } from 'src/app/core/services/config.service'
import { Subject } from 'rxjs'
import { MatIconModule } from '@angular/material/icon'
import { ButtonModule } from '../../button/button.module'
import { MatButtonModule } from '@angular/material/button'
import { AssetCardMiniComponent } from '../../asset-card-mini/asset-card-mini.component'
import { ClientService } from 'src/app/core/services/client.service'
import { NgxMaskPipe } from 'ngx-mask'
import { IContactDialogOptions } from 'src/app/core/interfaces/dialog.interface'
import { GeneralSettingsService } from 'src/app/core/services/general-settings.service'
import { CurrencyService } from 'src/app/core/services/currency.service'


declare global {
  interface Window {
    gtag: any
  }
}

@Component({
  selector: 'contact-dialog-component',
  standalone: true,
  template: `
    @let contactForm = configService.config().contactForm;
    @if (data.title) {
      <div mat-dialog-title [class.submission-confirmed]="submitTrue">
        @if (!submitTrue) {
          <span>{{data.title}}</span>
        }
        <div class="close-button-wrapper">
          <button mat-icon-button mat-dialog-close aria-label="Close dialog" i18n-aria-label="@@contactDialog_CloseDialogButtonAriaLabel">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    }
    <div mat-dialog-content (submitSuccess)="onSubmitSuccess()">
      @if (data.asset && !submitTrue) {
        @let assetCard = contactForm?.data?.components?.assetCard;
        @let badgesContainer = assetCard?.data?.components?.badgesContainer;
        <div class="left-side">
          <app-asset-card-mini
            [asset]="data.asset"
            [defaultImage]="generalSettings.getDefaultImage()"
            [currencyCode]="currencyService.selectedCurrency().code"
            [badgesConfig]="badgesContainer"
          ></app-asset-card-mini>
          @if (getPhone()) {
            <div class="phone overline" >
              <span i18n="@@contactDialog_CallUsLabel">Call us at:</span><br />
              <p class="body-1-medium">{{getPhone() | mask : '(000) 000-0000||+000000000000000' }}</p>
            </div>
          }
        </div>
      }
      <app-contact-form
        [successTitle]="contactForm.data.successTitle"
        [successBody]="contactForm.data.successBody"
        [hideSubmitButton]="true"
        [submitEventNotifier]="submitEventNotifier"
        [assetData]="data.asset"
        (submitSuccess)="onSubmitSuccess()"
        [withCloseButton]="true"
        [dialogRef]="dialogRef"
      ></app-contact-form>
    </div>
    @if (!submitTrue) {
      <div mat-dialog-actions class="desktop-actions">
        <app-button
          i18n="@@contactDialog_CancelButtonText"
          btn-large
          btn-outlined
          [mat-dialog-close]
        >Cancel</app-button>
        <app-button
          i18n="@@contactDialog_SendMessageButtonText"
          btn-large
          btn-contained
          (click)="submit()"
        >Send message</app-button>
      </div>
      <div mat-dialog-actions class="mobile-actions">
        <app-button
          i18n="@@contactDialog_SendMessageButtonText"
          btn-large
          btn-contained
          (click)="submit()"
        >Send message</app-button>
      </div>
    }
  `,
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    ContactFormComponent,
    ButtonModule,
    AssetCardMiniComponent,
    NgxMaskPipe,
  ]
})
export class ContactDialogComponent {
  window = inject(Window)
  protected submitEventNotifier = new Subject<void>()
  @HostBinding('class.submission-confirmed') submitTrue: boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IContactDialogOptions,
    public dialogRef: MatDialogRef<ContactDialogComponent>,
    protected configService: ConfigService,
    protected generalSettings: GeneralSettingsService,
    protected currencyService: CurrencyService,
    private _clientService: ClientService,
  ) {}

  submit(): void {
    this.submitEventNotifier.next()
  }

  onSubmitSuccess(): void {
    this.submitTrue = true
  }

  getPhone(): string {
    return this.data.asset?.branch_phone?.trim() ?? this._clientService.client().channel_webshop_selling_contact_phone?.trim() ?? ''
  }

  phoneClicked() {
    if (this.window.gtag) {
      this.window.gtag('event', 'phone_number_lead_click', {
        equipment_number: this.data.asset?.equipment_number,
        fleet_asset_id: this.data.asset?.fleet_asset_id,
      })
    }
  }
}
