import { DestroyRef, Injectable, inject } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http'
import { catchError, filter, map, retry } from 'rxjs/operators'
import { Observable, of } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { LogService } from './log.service'
import { IRequestOptions, IRequestParams, RequestMethods } from '../interfaces/fetch.interface'

@Injectable({
  providedIn: 'root'
})
export class FetchService {
  private maxRetries = 3

  private destroyRef = inject(DestroyRef)

  constructor(
    private http: HttpClient,
    private logger: LogService,
  ) { }

  public getRequest<T>(url: string, getParams?: IRequestParams, requestOptions?: IRequestOptions) {
    requestOptions = this.injectHeaderData(requestOptions, url)
    return this.makeRequest<T>('get', url, {}, requestOptions, getParams)
  }

  public postRequest<T>(url: string, body: any, requestOptions?: IRequestOptions, params?: IRequestParams) {
    requestOptions = this.injectHeaderData(requestOptions, url)
    return this.makeRequest<T>('post', url, body, requestOptions, params)
  }

  public putRequest<T>(url: string, body: any, requestOptions?: IRequestOptions, params?: IRequestParams) {
    requestOptions = this.injectHeaderData(requestOptions, url)
    return this.makeRequest<T>('put', url, body, requestOptions, params)
  }

  public patchRequest<T>(url: string, body: any, requestOptions?: IRequestOptions, params?: IRequestParams) {
    requestOptions = this.injectHeaderData(requestOptions, url)
    return this.makeRequest<T>('patch', url, body, requestOptions, params)
  }

  public deleteRequest<T>(url: string, requestOptions?: IRequestOptions, params?: IRequestParams) {
    requestOptions = this.injectHeaderData(requestOptions, url)
    return this.makeRequest<T>('delete', url, '', requestOptions, params)
  }

  public standAloneRequest<T>(method: RequestMethods, url: string, body?: any, standAloneRequestOptions?: any) {
    switch (method) {
      case 'post':
        return this.postRequest<T>(url, standAloneRequestOptions.body, standAloneRequestOptions.requestOptions, standAloneRequestOptions.query)
      case 'get':
        return this.getRequest<T>(url, standAloneRequestOptions?.query, standAloneRequestOptions?.requestOptions)
      case 'put':
        return this.putRequest<T>(url, standAloneRequestOptions.body, standAloneRequestOptions.requestOptions, standAloneRequestOptions.query)
      case 'patch':
        return this.patchRequest<T>(url, standAloneRequestOptions.body, standAloneRequestOptions.requestOptions, standAloneRequestOptions.query)
      case 'delete':
        return this.deleteRequest<T>(url, standAloneRequestOptions.requestOptions, standAloneRequestOptions.query)
      default:
        throw new Error('Request method not allowed: ' + method)
    }
  }

  private makeRequest<T>(method: string, url: string, body?: any, requestOptions?: any, params?: IRequestParams): Observable<T | HttpErrorResponse> {
    const request = new HttpRequest(method, url, body, { ...requestOptions, params })
    return this.http.request<T>(request).pipe(
      takeUntilDestroyed(this.destroyRef),
      retry(this.maxRetries),
      filter((event: HttpEvent<any>) => event.type !== 0),
      catchError(error => {
        this.handleError(error)
        return of(error)
      }),
      map((res: any) => {
        return res.body ?? res
      }),
    )
  }

  private injectHeaderData(requestOptions: any, url: string): any {
    return requestOptions
  }

  private handleError(error: HttpErrorResponse) {
    this.logger.error('An error occurred:', error?.error?.message ?? error?.message ?? error)
  }
}
