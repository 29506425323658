import { Injectable } from '@angular/core'
import { LogService } from './log.service'
import { environment } from 'src/environments/environment'
import { IEnvironment } from '../interfaces/environment.interface'

@Injectable()
export class EnvironmentService {

  private _environment!: IEnvironment

  constructor(
    private logger: LogService,
  ) {
    this._environment = environment
    logger.debug('Environment file set to: ', this._environment)
  }

  get(): IEnvironment {
    return this._environment
  }

}
