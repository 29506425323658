import { Injectable, WritableSignal, signal } from '@angular/core'
import { Router } from '@angular/router'
import { LogService } from './log.service'
import { ConfigService } from './config.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { EventService } from './event.service'
import { ILanguage, LanguageCode } from '../interfaces/language.interface'
import { ClientService } from './client.service'
import { DnsService } from './dns.service'
import { StorageService } from './storage.service'


export const usLanguage: ILanguage = {
  name: 'English (US)',
  adminName: 'English (United\u00a0States)',
  code: LanguageCode.EN_US,
}

export const supportedLanguages: ILanguage[] = [{
  ...usLanguage,
}, {
  name: 'English (CA)',
  adminName: 'English (Canada)',
  code: LanguageCode.EN_CA,
}, {
  name: 'Español (MX)',
  adminName: 'Spanish (Mexico)',
  code: LanguageCode.ES_MX,
}, {
  name: 'Español (SP)',
  adminName: 'Spanish (Spain)',
  code: LanguageCode.ES_SP,
}, {
  name: 'Français (CA)',
  adminName: 'French (Canada)',
  code: LanguageCode.FR_CA,
}]

declare global {
  interface Window { languageService: LanguageService; }
}

@Injectable()
export class LanguageService {

  private _localSelectedLanguageKey: string = 'selected-language'
  private _redirectKey: string = 'redirected-for-language'

  readonly redirectFromMessage = $localize`:@@languageService_redirectFromMessage:Language not in service. Redirected to the {language} version of the website.`

  allLanguages: WritableSignal<ILanguage[]> = signal(supportedLanguages)
  loadedLanguages: WritableSignal<ILanguage[]> = signal([])
  selectedLanguage: WritableSignal<ILanguage> = signal(usLanguage)

  constructor(
    private logger: LogService,
    private configService: ConfigService,
    private clientService: ClientService,
    private eventService: EventService,
    private dnsService: DnsService,
    private storageService: StorageService,
    private router: Router,
  ) {
    window.languageService = this

    this.eventService.languagesChanged$.pipe(
      takeUntilDestroyed(),
    ).subscribe(() => {
      this.loadLanguages()
    })
    this.eventService.languageSelected$.pipe(
      takeUntilDestroyed(),
    ).subscribe((code: LanguageCode) => {
      this.setSelectedLanguage(code)
      document.cookie = `selected_language=${code};path=/;domain=${window.location.hostname}`
      window.location.href = window.location.href.replace(/\/([a-z]{2})-([a-z]{2}(\/|$))/im, `/${code}/`)
    })
  }

  getDefaultLanguage() {
    const domain = window.location.hostname
    const defaultLang = this.configService.config().language.options[domain]?.default
      ?? this.configService.config().language.options.base.default
    if (!this.loadedLanguages().find(c => c.code === defaultLang)) {
      return LanguageCode.EN_US
    }
    return defaultLang
  }

  getAvailableLanguages() {
    const domain = window.location.hostname
    const primaryDomain = this.clientService.client().domain
    if (domain?.toLowerCase() === primaryDomain?.toLowerCase()) {
      return this.configService.config().language.options.base.available
    } else {
      return this.configService.config().language.options[domain]?.available
        ?? this.configService.config().language.options.base.available
    }
  }

  getAvailableLanguagesForAllDomains(): ILanguage[] {
    const langs = new Set<ILanguage>()
    const primaryDomain = this.clientService.client().domain
    const options = Object.entries(this.configService.config().language.options).filter(
      ([key]) =>
        // include base and any domain that is verified to be returned by the dns service
        ( key === 'base' || this.dnsService.domains().includes(key))
        // don't include any domain with an entry that matches the primary domain since that should be base now
        && key.toLowerCase() !== primaryDomain?.toLowerCase()
    )
    options.forEach(([, option]) => {
      option.available.forEach((code) => {
        const language = this.allLanguages().find(l => l.code === code)
        if (language) {
          langs.add(language)
        }
      })
    })
    return Array.from(langs)
  }

  getStoredLanguage() {
    return this.storageService.getItem(this._localSelectedLanguageKey)
  }

  initSelectedLanguage(): Promise<boolean> {
    const pathLang = window.location.pathname.split('/')[1]
    const localSelectedLanguage = this.getStoredLanguage() as LanguageCode
    const langCodeRegex = /[a-z]{2}-[A-Z]{2}/

    // check if there's no language in the path, e.g. localhost or legacy links pre-language support
    // links without a language code should be handled by nginx
    if (window.location.hostname === 'localhost' || !pathLang.match(langCodeRegex)) {
      // if we are, check if we have a selected language
      if (localSelectedLanguage) {
        // we do, so set it and return
        this.setSelectedLanguage(localSelectedLanguage)
        return Promise.resolve(true)
      } else {
        // we don't, so set the default and return
        this.setSelectedLanguage(this.getDefaultLanguage())
        return Promise.resolve(true)
      }
    }

    // check if we're on the selected language
    if (localSelectedLanguage && pathLang.toLowerCase() !== localSelectedLanguage.toLowerCase()) {
      // we're not on the selected language so check if selected language is available
      const selectedIsAvailable = this.loadedLanguages().find(lang => lang.code.toLowerCase() === localSelectedLanguage?.toLowerCase())
      if (selectedIsAvailable) {
        // selected is available so redirect to it
        this.redirectToLanguage(localSelectedLanguage as LanguageCode)
        return Promise.resolve(false)
      } else {
        // selected isn't available so remove it from local storage
        this.storageService.removeItem(this._localSelectedLanguageKey)
      }
    }

    // check if the lang we're on is available
    const pathLangIsAvailable = this.loadedLanguages().find(lang => lang.code.toLowerCase() === pathLang?.toLowerCase())
    if (!pathLangIsAvailable) {
      // if it's not available, redirect to the default language
      this.redirectToLanguageWithMessage(this.getDefaultLanguage())
      return Promise.resolve(false)
    }

    // the path we're on is available
    this.setSelectedLanguage(pathLang as LanguageCode)
    return Promise.resolve(true)
  }

  loadLanguages(): Promise<void> {
    const langs: ILanguage[] = []
    this.getAvailableLanguages().forEach(code => {
      const language = this.allLanguages().find(c => c.code === code)
      if (!language) {
        this.logger.warn('Tried to load unavailable language: ', code)
        return
      }
      langs.push(language)
    })

    this.loadedLanguages.set(langs)
    this.logger.debug('Languages loaded: ', this.loadedLanguages())
    return Promise.resolve()
  }

  initLanguagesForEmbedded(): void {
    this.loadedLanguages.set(window.parent.languageService.loadedLanguages())
    this.logger.debug('Languages loaded for embedded: ', this.loadedLanguages())
  }

  setSelectedLanguage(code: LanguageCode) {
    const setLanguage = this.loadedLanguages().find(c => c.code === code) ?? usLanguage
    this.selectedLanguage.set(setLanguage)
    this.storageService.setItem(this._localSelectedLanguageKey, setLanguage.code)
    this.logger.debug('Setting selected language to code: [', code, '] resulted in => ', this.selectedLanguage())
  }

  redirectToLanguageWithMessage(toLangCode: LanguageCode) {
    const redirectMessage = this.redirectFromMessage.replace('{language}', this.allLanguages().find(l => l.code === toLangCode)?.name ?? toLangCode)
    this.storageService.setItem(this._redirectKey, redirectMessage)
    this.redirectToLanguage(toLangCode)
  }

  redirectToLanguage(toLangCode: LanguageCode) {
    const currentUrl = window.location.href
    const regex = /\/[a-z]{2}-[A-Z]{2}\//
    let redirectUrl
    if (regex.test(currentUrl)) {
      // Replace the existing language code
      redirectUrl = currentUrl.replace(regex, `/${toLangCode}/`)
    } else {
      // Add the language code to the beginning of the path
      const url = new URL(currentUrl)
      url.pathname = `/${toLangCode}${url.pathname}`
      redirectUrl = url.toString()
    }
    window.location.href = redirectUrl
  }

  getRedirectMessage(): string | null {
    return this.storageService.getItem(this._redirectKey)
  }

  clearRedirectMessage() {
    this.storageService.removeItem(this._redirectKey)
  }
}
