@switch (status) {
  @case (AppInitStatus.Loading) {
    <ng-container *ngTemplateOutlet="loading"></ng-container>
  }
  @case (AppInitStatus.LoadingAdmin) {
    <ng-container *ngTemplateOutlet="loadingAdmin"></ng-container>
  }
  @case (AppInitStatus.Error) {
    <ng-container *ngTemplateOutlet="error"></ng-container>
  }
  @case (AppInitStatus.Success) {
    <ng-container *ngTemplateOutlet="success"></ng-container>
  }
  @case (AppInitStatus.SuccessAdmin) {
    <ng-container *ngTemplateOutlet="success"></ng-container>
  }
  @case (AppInitStatus.NotPublic) {
    <ng-container *ngTemplateOutlet="notPublic"></ng-container>
  }
}

<ng-template #loading>
  <!-- this will display while the non-admin experience is loading -->
  <div class="full-page-center">
    <div class="loading">
      <mat-spinner diameter="80"></mat-spinner>
      <p>Loading</p>
    </div>
  </div>
</ng-template>

<ng-template #loadingAdmin>
  <!-- this will display while the admin experience is loading -->
  <div class="full-page-center">
    <div class="loading">
      <mat-spinner diameter="80"></mat-spinner>
      <p>Setting things up&hellip;</p>
    </div>
  </div>
</ng-template>

<ng-template #error>
  <div class="full-page-center">
    <div class="error">
      <mat-icon class="material-symbols-outlined">warning</mat-icon>
      <h1 class="h5">Problem Loading Page</h1>
    </div>
  </div>
</ng-template>

<ng-template #success>
  <router-outlet></router-outlet>
</ng-template>

<ng-template #notPublic>
  <div class="not-public">
    <mat-icon class="light-primary-light">warning_amber</mat-icon>
    <div class="h5">Coming Soon</div>
    <div class="body-1 light-text-secondary">This page is a work in progress. Please check back later.</div>
  </div>
</ng-template>
