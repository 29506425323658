export enum LegacyImageProcessorTypes {
  Normal = 'Normal',
  Thumbnail = 'Thumbnail',
  HertzThumbnail = 'HertzThumbnail',
  URIFeaturedThumb = 'URIFeaturedThumb',
  FeaturedThumb = 'FeaturedThumb',
  FeaturedItem = 'FeaturedItem',
  Proxi = 'Proxi',
  ItemDetailBig = 'ItemDetailBig',
  ItemDetailLarge = 'ItemDetailLarge',
  ItemDetailExtended = 'ItemDetailExtended',
  ItemDetailExpanded = 'ItemDetailExpanded',
  ItemDetailZoom = 'ItemDetailZoom',
  CarouselItem = 'CarouselItem',
}

export interface ILegacyImageProcessorOptions {
  guid?: string | string[]
  transparent?: boolean
  type?: LegacyImageProcessorTypes
}

export const defaultLegacyImageProcessorOptions = {
  guid: 'none',
  transparent: false,
  type: LegacyImageProcessorTypes.CarouselItem,
}
