import { Injectable, signal } from '@angular/core'
import { ICurrency } from 'src/app/core/interfaces/currency.interface'

/**
 * This service is used to store global data that needs to be shared between components and services.
 * The main reason for this is to abstract the data into a service that has no dependencies.
 * This reduces configuration for tests with regards to dependencies.
 * As a general rule, the values stored in this service should be set from outside the service to keep dependencies to a minimum.
 */
@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {
  loadedCurrencies = signal<ICurrency[]>([])
  unitOfDistance = signal<'miles' | 'kilometers'>('miles')
}
