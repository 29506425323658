import { Injectable, signal } from '@angular/core'

export type StorageType = 'local' | 'session'

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  available = signal(false)

  constructor() {
    if (localStorage && sessionStorage) {
      this.available.set(true)
    }
  }

  setItem(key: string, value: string, storageType: StorageType = 'local'): void {
    const storage: Storage = storageType === 'local' ? localStorage : sessionStorage
    storage?.setItem(key, value)
  }

  removeItem(key: string, storageType: StorageType = 'local'): void {
    const storage: Storage = storageType === 'local' ? localStorage : sessionStorage
    storage?.removeItem(key)
  }

  getItem(key: string, storageType: StorageType = 'local'): string | null {
    const storage: Storage = storageType === 'local' ? localStorage : sessionStorage
    return storage?.getItem(key)
  }
}
