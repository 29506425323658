import { Component, Input, inject } from '@angular/core'
import { Router } from '@angular/router'


// These map to directives, e.g. icon-btn-icon-only, icon-btn-primary, etc
export enum IconButtonThemeType {
  Unstyled = 'unstyled',
  IconOnly = 'iconOnly',
  Outlined = 'outlined',
}
export enum IconButtonThemeStyle {
  Primary = 'primary',
  Secondary = 'secondary',
  White = 'white',
}
export enum IconButtonThemeSize {
  Small = 'small',
  Medium = 'medium',
}
export enum IconButtonIconSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
  private _router = inject(Router)
  IconButtonThemeType = IconButtonThemeType
  IconButtonThemeStyle = IconButtonThemeStyle
  IconButtonThemeSize = IconButtonThemeSize
  IconButtonIconSize = IconButtonIconSize
  @Input() matIcon: string = ''
  @Input() themeType?: IconButtonThemeType = IconButtonThemeType.IconOnly
  @Input() themeStyle?: IconButtonThemeStyle = IconButtonThemeStyle.Primary
  @Input() themeSize?: IconButtonThemeSize = IconButtonThemeSize.Medium
  @Input() iconSize?: IconButtonIconSize = IconButtonIconSize.Medium
  @Input() styleOverrides?: any = {}
  @Input() href?: string
  @Input() routerLink?: string | string[]
  @Input() disabled?: boolean = false
  @Input() ariaLabel?: string

  onClick() {
    if (this.routerLink) {
      this._router.navigate(typeof this.routerLink === 'string' ? [this.routerLink] : this.routerLink)
    } else if (this.href) {
      window.location.href = this.href
    }
  }
}
